import { PAGE_LOAD_SUCCESS } from '@avensia/nitro5-scope';
import { getSearchTextFromUrl } from './reducer';
import { componentIsSearch } from './current-page-is-search';
export default function (state = null, action) {
    if (componentIsSearch(state.componentName)) {
        const searchPage = state;
        switch (action.type) {
            case PAGE_LOAD_SUCCESS: {
                const pageLoadSuccessAction = action;
                const oldSearch = getSearchTextFromUrl(pageLoadSuccessAction.urlBeforeLoad);
                const newSearch = getSearchTextFromUrl(pageLoadSuccessAction.url);
                if (oldSearch && oldSearch !== newSearch) {
                    return Object.assign({}, searchPage, {
                        scrollPosition: 0,
                    });
                }
                return searchPage;
            }
            default:
                return searchPage;
        }
    }
    return state;
}
