import { useDispatch } from 'react-redux';
import { UPDATE_APP_SHELL_DATA, post } from '@avensia/nitro5-scope';
import { useSelector } from 'Shared/State';
import { useCallback } from 'react';
export default function useToggleVat() {
    const dispatch = useDispatch();
    const appShellData = useSelector(state => state.appShellData);
    const toggleFunction = useCallback(() => {
        const updateAppShellDataAction = {
            type: UPDATE_APP_SHELL_DATA,
            appShellData: Object.assign(Object.assign({}, appShellData), { displayPricesInclVat: !appShellData.displayPricesInclVat }),
        };
        dispatch(updateAppShellDataAction);
        post('/vatsettings/toggle');
    }, [appShellData, dispatch]);
    return toggleFunction;
}
