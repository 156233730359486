import { addUserLog } from '@avensia/nitro5-scope';
export const SET_RETAILERS = 'SET_RETAILERS';
export const SET_STARTRECORD = 'SET_STARTRECORD';
export const SET_RETAILERS_FETCH_DONE = 'SET_RETAILERS_FETCH_DONE';
export const FETCH_FIND_RETAILERS = 'FETCH_FIND_RETAILERS';
export const SET_CURRENT_RETAILERS = 'SET_CURRENT_RETAILERS';
export function setStartRecord(startRecord) {
    return {
        type: SET_STARTRECORD,
        startRecord,
    };
}
export function setRetailersFetchDone(isRetailerFetchDone = false) {
    return {
        type: SET_RETAILERS_FETCH_DONE,
        isRetailerFetchDone,
    };
}
export function fetchFindRetailers() {
    return {
        type: FETCH_FIND_RETAILERS,
    };
}
export function setRetailers(retailers) {
    addUserLog('Setting retailers');
    return {
        type: SET_RETAILERS,
        retailers,
    };
}
export function setCurrentRetailers(retailers, currentRetailers) {
    addUserLog('Setting current retailers');
    return {
        type: SET_CURRENT_RETAILERS,
        currentRetailers: (currentRetailers === null || currentRetailers === void 0 ? void 0 : currentRetailers.length) > 0 ? [...new Set([...currentRetailers, ...retailers])] : retailers,
        retailers,
    };
}
