import { Breakpoint } from '@avensia/nitro5-scope';
import { media } from '@glitz/core';
import { styled } from '@glitz/react';
import { SigvarisPageWidth } from 'Shared/Sigvaris';
import { mediaMinSmall } from '.';
export const pageNarrowBreakpoint = Breakpoint.Small;
export const pageDefaultBreakpoint = Breakpoint.Medium;
export const pageNormalBreakpoint = Breakpoint.Large;
export const pageWideBreakpoint = Breakpoint.Huge;
export const breakoutEditorBlock = styled(Object.assign({}, media(mediaMinSmall, {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: SigvarisPageWidth.ContentPageWidth,
    boxSizing: 'content-box',
})));
export const breakoutVideoBlock = styled(Object.assign({}, media(mediaMinSmall, {
    width: '100vw',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: SigvarisPageWidth.ContentPageWidth,
    padding: { x: 40 },
    boxSizing: 'content-box',
})));
export const breakoutProductCard = styled(Object.assign({}, media(mediaMinSmall, {
    width: '100%',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: 1100,
    padding: { x: 16 },
    boxSizing: 'content-box',
})));
export const fullViewportWidthBlock = {
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    maxWidth: 'unset',
};
