import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/nitro5-scope';
import { Plus, Minus } from 'Shared/Icon';
import { Theme as ButtonTheme, createFeedbackStandaloneButton } from '../Button/Feedback';
import { ThemeButton, createRoundButtonStyled } from 'Shared/Button';
import { BareInputWithoutFocusVisuals } from 'Shared/Fields/Text';
import { selector } from '@glitz/core';
import { General, thin, visuallyHiddenDecorator, gigantic, huge, lightBorderGrey, stateRed, linkColor, } from 'Shared/Style';
import { testIdProps } from '../../test-automation';
import { createTabbingFocusElement } from 'Shared/a11y/TabFocus';
export const MAX_LIMIT_COUNT = 999;
const MAX_LENGTH = 3;
function isValidQuantity(quantity, maxQuantity = MAX_LIMIT_COUNT) {
    if (typeof quantity !== 'number') {
        return false;
    }
    return quantity >= 0 && quantity <= maxQuantity;
}
function parse(int) {
    return parseInt(int, 10);
}
export default class Quantity extends React.Component {
    constructor(props) {
        super(props);
        this.isFocused = false;
        this.reduce = () => {
            const quantity = parse(this.state.proxyValue) - 1;
            if (isValidQuantity(quantity)) {
                return this.change(quantity);
            }
            else {
                this.setState({
                    invalid: true,
                });
            }
            return Promise.reject(null);
        };
        this.add = () => {
            const quantity = (parse(this.state.proxyValue) || 0) + 1;
            if (isValidQuantity(quantity, this.props.maxQuantity)) {
                return this.change(quantity);
            }
            else {
                this.setState({
                    invalid: true,
                });
            }
            return Promise.reject(null);
        };
        this.state = {
            proxyValue: props.value,
            invalid: false,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.isFocused) {
            return;
        }
        if (this.state.proxyValue !== nextProps.value) {
            this.setState({
                proxyValue: nextProps.value,
            });
        }
    }
    componentWillUnmount() {
        this.setState({
            proxyValue: '',
            invalid: false,
        });
    }
    change(value) {
        const parsedValue = typeof value === 'number' ? value : parse(value);
        this.setState({
            proxyValue: parsedValue > MAX_LIMIT_COUNT ? MAX_LIMIT_COUNT : parsedValue || '',
            invalid: parsedValue > MAX_LIMIT_COUNT,
        });
        return this.update(parsedValue);
    }
    update(quantity) {
        const previousValue = this.state.proxyValue;
        return this.props
            .update(quantity)
            .then(() => {
            if (this.state.proxyValue === this.props.value && this.state.invalid) {
                this.setState({
                    invalid: false,
                });
            }
        })
            .catch(() => {
            if (!this.isFocused) {
                // Reset hard if field is not focused
                this.setState({
                    proxyValue: previousValue,
                    invalid: true,
                });
            }
            else if (!this.state.invalid) {
                this.setState({
                    invalid: true,
                });
            }
        });
    }
    verify() {
        if (this.state.invalid) {
            this.setState({
                proxyValue: this.props.value,
                invalid: false,
            });
        }
    }
    onInput(e) {
        const { currentTarget } = e;
        //Ensure that current value do not exceed with the maxlength in qty field
        if (currentTarget.value.length > currentTarget.maxLength)
            currentTarget.value = currentTarget.value.slice(0, currentTarget.maxLength);
    }
    //Prevent non-numeric chars that are allowed in type=number in quantity field
    onKeyDown(e) {
        return ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    }
    render() {
        return (React.createElement(Base, { css: this.state.invalid && borderInvalidStyle },
            React.createElement(RoundFeedbackButton, Object.assign({ title: translate('/Product/MinimizeQty'), as: ButtonTheme.None, onClick: this.reduce, "aria-label": translate('/Cart/DecreaseQuantity') }, testIdProps.DecreaseQuantityButton),
                React.createElement(Minus, null)),
            React.createElement(Label, null,
                React.createElement(HiddenForA11y, null, translate('/Shared/Quantity')),
                React.createElement(Input, Object.assign({ type: "number", value: this.state.proxyValue }, testIdProps.QuantityInput, { onChange: e => this.change(e.currentTarget.value), onFocus: () => {
                        this.isFocused = true;
                    }, onBlur: e => {
                        this.change(e.currentTarget.value);
                        this.isFocused = false;
                        this.verify();
                    }, maxLength: MAX_LENGTH, onInput: this.onInput, onKeyDown: this.onKeyDown }))),
            React.createElement(RoundFeedbackButton, Object.assign({ title: translate('/Product/MaximizeQty'), as: ButtonTheme.None, onClick: this.add, "aria-label": translate('/Cart/IncreaseQuantity') }, testIdProps.IncreaseQuantityButton),
                React.createElement(Plus, null))));
    }
}
export const borderInvalidStyle = {
    border: { xy: { color: stateRed } },
};
const Input = createTabbingFocusElement(styled(BareInputWithoutFocusVisuals, Object.assign({ width: gigantic, textAlign: 'center', margin: { x: 0 }, MozAppearance: 'textfield' }, selector(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
    WebkitAppearance: 'none',
}))));
const Base = styled.span({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: huge,
    border: {
        xy: {
            color: lightBorderGrey,
            style: 'solid',
            width: thin,
        },
        radius: 0,
    },
});
const HiddenForA11y = styled(styled.Span, visuallyHiddenDecorator);
const Label = styled(styled.Label, { display: 'flex', margin: { xy: 0 } });
const RoundFeedbackButton = createFeedbackStandaloneButton(styled(ThemeButton, createRoundButtonStyled(General.Small), { color: linkColor }));
