import { addUserLog, postJson } from '@avensia/nitro5-scope';
import { trackAddToWishList, trackRemoveToWishList } from 'TrackingInformation';
export const OPEN_WISHLIST = 'OPEN_WISHLIST';
export const CLOSE_WISHLIST = 'CLOSE_WISHLIST';
export const ADD_WISHLIST_ITEM = 'ADD_WISHLIST_ITEM';
export const REMOVE_WISHLIST_ITEM = 'REMOVE_WISHLIST_ITEM';
export function openWishList() {
    addUserLog('Toggling wish list');
    return {
        type: OPEN_WISHLIST,
    };
}
export function closeWishList() {
    addUserLog('Close wish list');
    return {
        type: CLOSE_WISHLIST,
    };
}
export function addToWishList(model) {
    return (dispatch) => {
        postJson('/wishlist/save', model).then(() => {
            trackAddToWishList(model.productCode, model.variationCode);
            dispatch({
                type: ADD_WISHLIST_ITEM,
                productCode: model.variationCode || model.productCode,
            });
        });
    };
}
export function removeFromWishList(model) {
    return (dispatch) => {
        postJson('/wishlist/deleteEntry', model).then(() => {
            trackRemoveToWishList(model.productCode, model.variationCode);
            dispatch({
                type: REMOVE_WISHLIST_ITEM,
                productCode: model.variationCode || model.productCode,
            });
        });
    };
}
