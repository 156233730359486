import { URLX, get, pathCombine, addUserLog } from '@avensia/nitro5-scope';
import { searchPageUrl } from 'Shared/known-urls';
import * as searchHistory from './search-history';
export const NO_SEARCH_RESULTS = 'NO_SEARCH_RESULTS';
export const CLICK_LINK_ITEM = 'CLICK_LINK_ITEM';
export const UNCLICK_LINK_ITEM = 'UNCLICK_LINK_ITEM';
export var Suggestion;
(function (Suggestion) {
    Suggestion["Related"] = "related";
    Suggestion["History"] = "history";
    Suggestion["Popular"] = "popular";
})(Suggestion || (Suggestion = {}));
export const SEARCH_PAGE_LOAD_QUERY_NAME = 'search';
export const SEARCH_QUERY_NAME = 'q';
export const SEARCH_COUNT_NAME = 'count';
export const SEARCH_SORT_NAME = 'sortBy';
// This hidden query helps us identify page loads that's not
// triggered by these requests so we're able to hide quick
// search on a regular navigation on the page
export function searchQuery(url) {
    const clone = new URLX(url);
    clone.hiddenParams.set(SEARCH_PAGE_LOAD_QUERY_NAME, String(true));
    return clone;
}
const SUGGESTION_COUNT = 5;
const SEARCH_HISTORY_COUNT = 5;
let lastRequestId = 0;
export function clickLinkItem() {
    addUserLog('click link item');
    return {
        type: CLICK_LINK_ITEM,
    };
}
export function unClickLinkItem() {
    addUserLog('unclick link item');
    return {
        type: UNCLICK_LINK_ITEM,
    };
}
export function loadNoSearchResults(isPreview) {
    addUserLog('Loading all recent search');
    return (dispatch) => {
        const filteredSearchHistory = searchHistory
            .getAll(searchTextToWords(''))
            .slice(0, SEARCH_HISTORY_COUNT)
            .map(phrase => ({ text: phrase }));
        dispatch({
            type: NO_SEARCH_RESULTS,
            id: Suggestion.History,
            items: filteredSearchHistory,
        });
        const requestId = (lastRequestId = Math.random());
        const url = new URLX(pathCombine(searchPageUrl(), 'searchsuggestions'));
        url.searchParams.set(SEARCH_COUNT_NAME, String(SUGGESTION_COUNT));
        if (isPreview) {
            url.hiddenParams.set('track', String(false));
        }
        return get(url)
            .then(r => r.json())
            .then((json) => {
            if (requestId === lastRequestId) {
                dispatch({
                    type: NO_SEARCH_RESULTS,
                    id: Suggestion.Popular,
                    items: json,
                });
            }
        });
    };
}
export function searchTextToWords(searchText) {
    return searchText.replace(/\s+/, ' ').split(' ');
}
