import { addUserLog } from '@avensia/nitro5-scope';
export const TOGGLE_MADE_TO_MEASURE = 'TOGGLE_MADE_TO_MEASURE';
export const SET_MODAL_TAB_INDEX = 'SET_MODAL_TAB_INDEX';
export function setModalTabIndex(modalTabIndex) {
    return {
        type: SET_MODAL_TAB_INDEX,
        modalTabIndex: modalTabIndex,
    };
}
export function toggleMadeToMeasure() {
    addUserLog('Toggling made to measure');
    return {
        type: TOGGLE_MADE_TO_MEASURE,
    };
}
