import React from 'react';
import { styled } from '@glitz/react';
import Image, { Preset, Ratio } from 'Shared/Image/Ratio';
import { large, sourceSansPro } from 'Shared/Style';
import { translate, useDynamicData } from '@avensia/nitro5-scope';
import { getCurrentUser } from 'Shared/dynamic-data';
import AddToWishlist from 'Wishlist';
import { AdditionalContentBox, Body, Content, Media, Title } from 'SiteLayout/MiniCart/Item';
export default function WishListItem({ item }) {
    const { code, color, compression, displayName, footType, imageUrl, leftRight, length, model, pharmaCode, size, sku, typeOfBand, url, } = item;
    const currentUser = useDynamicData(getCurrentUser());
    const isB2B = currentUser === null || currentUser === void 0 ? void 0 : currentUser.isB2B;
    return (React.createElement(Item, null,
        React.createElement(Media, { to: url },
            React.createElement(Image, { src: imageUrl, ratio: Ratio.TwoToThree, preset: Preset.Tiny, alt: displayName })),
        React.createElement(Body, null,
            React.createElement(Flex, null,
                React.createElement(Content, null,
                    React.createElement(Title, { to: url }, displayName)),
                React.createElement(Favorite, { code: code, transparent: true }),
                React.createElement(AdditionalContentBox, null,
                    React.createElement(Additional, null,
                        sku && React.createElement(Cell, null, `${translate('/Facets/sku')}: ${sku}`),
                        pharmaCode && isB2B && React.createElement(Cell, null, `${translate('/Facets/pharmaCode')}: ${pharmaCode}`),
                        model && React.createElement(Cell, null, `${translate('/Facets/model')}: ${model}`),
                        compression && React.createElement(Cell, null, `${translate('/Facets/CCL')} : ${compression}`),
                        size && React.createElement(Cell, null, `${translate('/Facets/size')} : ${size}`),
                        color && React.createElement(Cell, null, `${translate('/Facets/color')}: ${color}`),
                        typeOfBand && React.createElement(Cell, null, `${translate('/Facets/typeOfBand')}: ${typeOfBand}`),
                        footType && React.createElement(Cell, null, `${translate('/Facets/footType')}: ${footType}`),
                        length && React.createElement(Cell, null, `${translate('/Facets/length')}: ${length}`),
                        leftRight && React.createElement(Cell, null, `${translate('/Cart/Additional/Unit')}: ${leftRight}`)))))));
}
const Item = styled.li({
    position: 'relative',
    display: 'flex',
    marginTop: large,
});
const Flex = styled.div({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
});
const Favorite = styled(AddToWishlist, {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 'auto',
    padding: { y: 0 },
});
const Additional = styled.div(sourceSansPro(12), {
    display: 'flex',
    flexDirection: 'column',
});
const Cell = styled.span({});
