import React, { useRef } from 'react';
import connect from 'Shared/connect';
import { closeAccountPanel } from './action-creators';
import Account from './index';
import Snackbar, { useSnackbar } from 'SiteLayout/Tray/Snackbar';
import Flyout from 'Shared/Flyout';
function AccountPanel(props) {
    const pushSnackbar = useSnackbar();
    const lastMessageRef = useRef(props.accountMessage);
    const lastRemoveRef = useRef(null);
    if (lastMessageRef.current !== props.accountMessage) {
        if (lastRemoveRef.current) {
            lastRemoveRef.current();
            lastRemoveRef.current = null;
        }
        if (props.accountMessage) {
            lastRemoveRef.current = pushSnackbar(React.createElement(Snackbar, null, props.accountMessage));
        }
        lastMessageRef.current = props.accountMessage;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Flyout, { isOpen: props.accountPanelIsOpen, onClose: props.closeAccountPanel },
            React.createElement(Account, null))));
}
export default connect((state) => ({
    accountPanelIsOpen: state.userAccount.accountPanelIsOpen,
    accountMessage: state.userAccount.accountMessage,
}), (dispatch) => ({
    closeAccountPanel() {
        return dispatch(closeAccountPanel());
    },
}))(AccountPanel);
