import React, { useState } from 'react';
import { styled } from '@glitz/react';
import Accordion from 'Shared/Accordion';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
import Social from './Social';
import Link from 'Shared/Link';
import * as style from 'Shared/Style';
import { margin, epsilon, figma, mediaMinMicro, small, FontWeight } from 'Shared/Style';
import { resetInputStyled } from 'Shared/Fields/Text';
import { useSelector } from 'Shared/State';
import { h4Decorator } from 'Shared/Generic/H4';
import { Hr } from 'Shared/Generic';
import { translate } from '@avensia/nitro5-scope';
import { resetLinkDecorator } from 'Shared/Generic/A';
import { h5Decorator } from 'Shared/Generic/H5';
import { media } from '@glitz/core';
import { format } from 'Shared/Translate';
export default function CompactFooter() {
    var _a, _b;
    const [openGroup, setOpenGroup] = useState(null);
    const footer = useSelector(state => state.footer);
    const footerLinks = (_a = footer.lowerLinks) === null || _a === void 0 ? void 0 : _a.links;
    function onLinkGroupToggle(groupIndex) {
        setOpenGroup(groupIndex === openGroup ? null : groupIndex);
    }
    return (React.createElement(Footer, { appearance: [PageAppearance.Normal, PageAppearance.Gap] }, (_b = footer.linkGroups) === null || _b === void 0 ? void 0 :
        _b.map((item, i) => {
            var _a;
            return (React.createElement(LinkGroup, { key: i, header: React.createElement(StyledHeader, null, item.header), onToggle: () => onLinkGroupToggle(i), isOpen: i === openGroup }, (_a = item.links) === null || _a === void 0 ? void 0 : _a.map((linkItem, index) => (React.createElement(GroupItem, { key: index, to: linkItem.url }, linkItem.title)))));
        }),
        React.createElement(SocialWrapper, null,
            React.createElement(StyledHeader, null, translate('/Footer/FollowUs')),
            React.createElement(Social, { footer: footer })),
        React.createElement(Hr, null),
        React.createElement(FooterLinks, null,
            React.createElement(StyledSpan, null, format(translate('/Footer/CopyrightText'), new Date().getFullYear())), footerLinks === null || footerLinks === void 0 ? void 0 :
            footerLinks.map((item, i) => (React.createElement(FooterLink, { key: i, to: item.href, target: item.target }, item.title))))));
}
const Footer = styled(Basic, {
    color: ({ siteTheme }) => siteTheme.footer.textColor,
});
const LinkGroup = styled(Accordion, {});
const GroupItem = styled(Link, resetInputStyled, {
    display: 'block',
    padding: { bottom: style.medium, left: style.small },
    fontSize: epsilon,
});
const Header = styled(styled.P, h4Decorator, {
    margin: { x: margin.medium, y: margin.large },
    lineHeight: style.lineHeight,
    fontWeight: FontWeight.SemiBold,
});
const StyledHeader = styled(Header, Object.assign({}, media(mediaMinMicro, { fontSize: figma })));
const SocialWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});
const FooterLinks = styled(styled.H5, h5Decorator, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontWeight: 'normal',
});
const FooterLink = styled(Link, resetLinkDecorator, {
    font: {
        weight: 'normal',
        size: style.epsilon,
    },
    margin: { y: small },
});
const StyledSpan = styled.span({
    marginBottom: small,
    font: {
        weight: 'bold',
        size: style.epsilon,
    },
});
