import React, { useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import { translate, DynamicData } from '@avensia/nitro5-scope';
import Price from 'Pricing/Price';
import { fullButtonStyled, LargeButton, Theme, Casing, LinkStyledButton } from 'Shared/Button';
import Item, { itemKey } from './Item';
import { useCheckoutActions, useCheckoutState } from 'Checkout/Context';
import { getCart } from 'Shared/dynamic-data';
import { Ul, P } from 'Shared/Generic';
import { checkoutPageUrl } from 'Shared/known-urls';
import { listNames } from 'TrackingInformation';
import { margin, large, epsilon, white, medium, sourceSansPro, zeta, average, sourceSansProSemiBold, huge, FontWeight, } from 'Shared/Style';
import { Cart } from 'Shared/Icon';
import { Body as FlyoutBody, Header } from 'Shared/Flyout';
import { testIdProps } from '../../test-automation';
import { useMiniCart } from './use-minicart';
import FeatureToggler from 'FeatureToggles/FeatureToggler';
import ToggleVat from './ToggleVat';
import { selector } from '@glitz/core';
import RoundIndicator from 'Shared/RoundIndicator';
import { useViewMiniCartGa4Gracking } from 'TrackingInformation/ga4';
export default function MiniCart() {
    const actions = useCheckoutActions();
    const state = useCheckoutState();
    const { toggleMiniCart, miniCartIsDisabled } = useMiniCart();
    const [hasBeenOpen, setHasBeenOpen] = useState(false);
    useViewMiniCartGa4Gracking(hasBeenOpen);
    useEffect(() => {
        if (!hasBeenOpen) {
            setHasBeenOpen(!hasBeenOpen);
        }
        else {
            setHasBeenOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(DynamicData, { data: getCart(), shouldResolve: !miniCartIsDisabled }, cart => {
        const cartCount = cart.items.reduce((a, i) => a + i.quantity, 0);
        return (React.createElement(Base, Object.assign({}, testIdProps.MiniCartElement),
            React.createElement(Header, { onClose: toggleMiniCart },
                React.createElement(StyledLinkButton, null,
                    React.createElement(Cart, null),
                    cartCount > 0 && React.createElement(RoundIndicator, { cartCount: cartCount })),
                React.createElement(Title, null, translate('/MiniCart/Title'))),
            React.createElement(Body, null, cart.items.length > 0 ? (React.createElement(Ul, { column: true }, cart.items.map((item, index) => (React.createElement(Product, Object.assign({ key: itemKey(item, index), updateCartItemQuantity: quantity => actions.updateCartItemQuantity(item.code, item.quantity, quantity, listNames.cart), cartIsUpdating: state.isUpdating }, item, { setAdditionalData: () => actions.setAdditionalData({
                    rejectedGifts: [...cart.additional.rejectedGifts, item.code],
                }) })))))) : (React.createElement(Empty, null, translate('/MiniCart/EmptyCart')))),
            cart.items.length > 0 && (React.createElement(Footer, null,
                React.createElement(Pricing, null,
                    React.createElement(Row, null,
                        React.createElement("span", null, `${translate('/MiniCart/ToPay')} `),
                        React.createElement("span", null,
                            React.createElement(Price, { current: cart.viewData.total.exclVat, nonBoldPrice: true }))),
                    React.createElement(FeatureToggler, { featureName: 'toggleDisplayPricesInclExclVAT' },
                        React.createElement(ToggleVat, null)),
                    React.createElement(TextVat, null, `${translate('/Minicart/ExcludingVat')}`)),
                React.createElement(FullButton, Object.assign({ as: Theme.Primary, casing: Casing.None, to: checkoutPageUrl(), disabled: !cart.items.length }, testIdProps.GoToCheckoutButton), translate('/MiniCart/ToCheckout'))))));
    }));
}
const Base = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
});
const Title = styled.span({
    marginLeft: margin.medium,
    fontWeight: FontWeight.SemiBold,
});
const StyledLinkButton = styled(LinkStyledButton, Object.assign({ marginLeft: -average }, selector(':hover', { backgroundColor: white })));
const Body = styled(FlyoutBody, {
    flex: {
        grow: 1,
        shrink: 1,
        basis: 'auto',
    },
    overflow: 'auto',
    position: 'relative',
});
const Product = styled(Item, {
    marginBottom: margin.large,
    fontSize: ({ isCompact }) => (isCompact ? average + 2 : medium),
});
const Empty = styled(P, sourceSansProSemiBold(medium), {
    textAlign: 'center',
});
const Footer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    flex: {
        grow: 0,
        shrink: 0,
        basis: 'auto',
    },
    textAlign: 'left',
    padding: {
        bottom: ({ isCompact }) => (isCompact ? '10%' : '20%'),
        x: huge,
    },
});
const TextVat = styled.span(sourceSansPro(epsilon), {
    paddingTop: zeta,
});
const FullButton = styled(LargeButton, fullButtonStyled);
const Row = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});
const Pricing = styled.div({
    display: 'flex',
    flexDirection: 'column',
    margin: {
        y: large,
    },
});
