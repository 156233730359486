import React, { useMemo } from 'react';
import { styled } from '@glitz/react';
import { formatPrice } from 'Shared/format-price';
import { useSelector } from 'Shared/State';
import { small, FontWeight, darkRed, black } from 'Shared/Style';
import { useIsCompact } from 'Shared/use-viewport';
import { useDynamicData } from '@avensia/nitro5-scope';
import { getCurrentUser } from 'Shared/dynamic-data';
export default styled(function Price(props) {
    const { current, original, maxPrice, variationPrices, selectedVariationCode, explicitCurrency, nonBoldPrice, horizontal, isLoading, isNegative, isHalfPairSelected, isMtmSizeSelected, isHalfPairAvailable, forceDisplayPricesInclVat, } = props;
    const isCompact = useIsCompact();
    const displayPricesInclVatSetting = useSelector(state => state.appShellData.displayPricesInclVat);
    const currentLanguage = useSelector(state => state.appShellData.currentCountry.language);
    const displayPricesInclVat = displayPricesInclVatSetting || forceDisplayPricesInclVat;
    const countrySettings = useSelector(state => state.appShellData.countrySettings);
    const currentUser = useDynamicData(getCurrentUser());
    let priceMultiplier = 1;
    // SeriesHalfPair => / 2
    if (countrySettings.isHalfPairEnabled && isHalfPairAvailable && isHalfPairSelected && !isMtmSizeSelected) {
        priceMultiplier = 0.5;
    }
    // MtmVariant Pair => * 2
    if (isHalfPairAvailable && !isHalfPairSelected && isMtmSizeSelected) {
        priceMultiplier = 2;
    }
    if (isNegative) {
        priceMultiplier *= -1;
    }
    let currency = useSelector(state => state.appShellData.currency);
    if (explicitCurrency) {
        currency = explicitCurrency;
    }
    // NITROFIX: This should be the only place you have to change if you want to show
    // price without vat
    const currentPriceToShow = (selectedVariationCode
        ? displayPricesInclVat
            ? variationPrices[selectedVariationCode].current.inclVat
            : variationPrices[selectedVariationCode].current.exclVat
        : typeof current === 'number'
            ? current
            : displayPricesInclVat
                ? current.inclVat
                : current.exclVat) * priceMultiplier;
    let originalPriceToShow = selectedVariationCode
        ? displayPricesInclVat
            ? variationPrices[selectedVariationCode].original.inclVat
            : variationPrices[selectedVariationCode].original.exclVat
        : original
            ? typeof original === 'number'
                ? original
                : displayPricesInclVat
                    ? original.inclVat
                    : original.exclVat
            : undefined;
    if (originalPriceToShow) {
        originalPriceToShow *= priceMultiplier;
    }
    let currentMaxPriceToShow = null;
    let originalMaxPriceToShow = null;
    if (maxPrice) {
        currentMaxPriceToShow =
            typeof maxPrice.current === 'number'
                ? maxPrice.current
                : displayPricesInclVat
                    ? maxPrice.current.inclVat
                    : maxPrice.current.exclVat;
        if (currentMaxPriceToShow) {
            currentMaxPriceToShow *= priceMultiplier;
        }
        originalMaxPriceToShow = maxPrice.original
            ? typeof maxPrice.original === 'number'
                ? maxPrice.original
                : displayPricesInclVat
                    ? maxPrice.original.inclVat
                    : maxPrice.original.exclVat
            : undefined;
        if (originalMaxPriceToShow) {
            originalMaxPriceToShow *= priceMultiplier;
        }
    }
    const hasPriceRange = currentMaxPriceToShow && currentMaxPriceToShow !== currentPriceToShow;
    const isDiscounted = originalPriceToShow && originalPriceToShow > currentPriceToShow;
    const priceDecorator = useMemo(() => styled({
        color: isDiscounted && !(currentUser === null || currentUser === void 0 ? void 0 : currentUser.isB2B) ? darkRed : 'inherit',
        fontWeight: nonBoldPrice ? FontWeight.Normal : FontWeight.SemiBold,
        paddingRight: !isCompact && horizontal ? small : 0,
    }), [horizontal, isCompact, nonBoldPrice, isDiscounted, currentUser === null || currentUser === void 0 ? void 0 : currentUser.isB2B]);
    const directionDecorator = useMemo(() => styled({
        display: horizontal ? 'inline' : 'block',
    }), [horizontal]);
    const css = useMemo(() => {
        return [priceDecorator, directionDecorator];
    }, [directionDecorator, priceDecorator]);
    return (React.createElement(styled.Span, { css: isLoading && { opacity: 0.5 } },
        isDiscounted && !(currentUser === null || currentUser === void 0 ? void 0 : currentUser.isB2B) && (React.createElement(Original, { css: directionDecorator }, !selectedVariationCode && hasPriceRange
            ? `${formatPrice(originalPriceToShow, currency, currentLanguage)} - ${formatPrice(originalMaxPriceToShow, 'default', currentLanguage)}`
            : formatPrice(originalPriceToShow, currency, currentLanguage))),
        React.createElement(styled.Span, { css: css }, !selectedVariationCode && hasPriceRange
            ? `${formatPrice(currentPriceToShow, currency, currentLanguage)} - ${formatPrice(currentMaxPriceToShow, currency, currentLanguage)}`
            : formatPrice(currentPriceToShow, currency, currentLanguage))));
}, {
    display: 'inline-block',
});
const Original = styled.span({
    textDecoration: 'line-through',
    color: black,
    fontWeight: FontWeight.Normal,
});
