import { useEffect, useRef } from 'react';
import { isBrowser, on } from '@avensia/nitro5-scope';
export function useClickOutside(ref, handler) {
    const handlerRef = useLatest(handler);
    useEffect(() => {
        if (!isBrowser() || !handlerRef) {
            return;
        }
        const unsubscribe = on('click', event => {
            if (!ref.current || !handlerRef.current || ref.current.contains(event.target)) {
                return;
            }
            handlerRef.current(event);
        });
        return unsubscribe;
    }, [handlerRef, ref]);
}
function useLatest(value) {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    });
    return ref;
}
