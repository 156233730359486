import React from 'react';
import { styled } from '@glitz/react';
import { Error as ErrorIcon } from 'Shared/Icon';
import { H3 } from 'Shared/Generic';
import { translate, clearCacheAndUnregisterServiceWorker } from '@avensia/nitro5-scope';
import Button, { Theme as ButtonTheme } from 'Shared/Button';
import connect from 'Shared/connect';
import { negative, margin } from 'Shared/Style';
export default connect((state) => ({
    currentUrl: state.currentPage.url,
}))(styled(class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            hasCaughtError: false,
            hasReRendered: false,
            errorAppearedOn: '',
        };
        this.onRetryClick = () => {
            this.setState({ hasReRendered: true, hasCaughtError: false });
        };
        this.hardReset = () => {
            clearCacheAndUnregisterServiceWorker().then(() => this.props.reloadCurrentPage ? window.location.reload() : (window.location.href = '/'));
        };
    }
    static getDerivedStateFromProps(newProps, state) {
        if (state.hasCaughtError && state.errorAppearedOn !== newProps.currentUrl) {
            return { hasCaughtError: false, hasReRendered: false };
        }
        return null;
    }
    componentDidCatch(error, errorInfo) {
        if (!!window.rg4js) {
            window.rg4js('send', error);
            window.rg4js('customTags', errorInfo);
        }
        if (this.props.callback) {
            this.props.callback(error, errorInfo, this.state.errorAppearedOn);
        }
        if (this.state.hasReRendered) {
            this.hardReset();
        }
        else {
            this.setState({
                hasCaughtError: true,
                errorAppearedOn: this.props.currentUrl || window.location.pathname,
            });
        }
    }
    render() {
        return this.state.hasCaughtError ? (React.createElement(Container, null,
            React.createElement(ErrorIcon, { css: { fontSize: '3.5em' } }),
            React.createElement(H3, { css: {
                    color: negative,
                    margin: { y: margin.tiny },
                } }, translate('/Shared/Error/Heading')),
            React.createElement(styled.P, { css: { textAlign: 'center' } }, translate('/Shared/Error/UnknownError')),
            React.createElement(Button, { as: ButtonTheme.Primary, onClick: this.onRetryClick }, translate('/Shared/Error/CTA')))) : (this.props.children);
    }
}));
const Container = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'white',
    color: negative,
    padding: { xy: margin.medium },
});
