import { __rest } from "tslib";
import { CLEAR_SELECTED_PROPERTIES, SELECT_PROPERTY, SET_DEFAULT_PROPERTIES, SET_MATCHING_SERIES_PROPERTIES, CLEAR_SELECTED_PROPERTY, } from './action-creators';
import { PAGE_LOAD } from '@avensia/nitro5-scope';
import { PROPERTY_ID_SIZE_MTM, PROPERTY_NAME_LENGTH, PROPERTY_NAME_UNIT } from './constants';
const defaultState = {
    selectedProperties: {},
    latestSelectedPropertyName: null,
    latestSelectedPropertyTime: Date.now(),
    variations: [],
    availableFacets: [],
    selectedVariationCode: null,
    selectedUnitProperty: null,
    hasSelectedProperties: false,
    units: [],
    matchingSeriesLengthProperties: [],
    matchingSeriesSizeProperties: [],
    initialized: false,
};
const getDefaultSelectedProperties = (availableFacets) => {
    const facets = {};
    availableFacets.forEach(f => {
        if (f.facetValues.length === 1) {
            facets[f.fieldName] = {
                id: f.facetValues[0].id,
                value: f.facetValues[0].text,
            };
        }
    });
    return facets;
};
const getSelectedVariationProperties = (variationCode, variations, availableFacets) => {
    const selectedVariation = variations === null || variations === void 0 ? void 0 : variations.find(v => v.code === variationCode);
    if (selectedVariation) {
        const facets = {};
        availableFacets.forEach(f => {
            const facetValue = f.facetValues.find(fv => fv.text === selectedVariation[fv.fieldName]);
            if (facetValue) {
                facets[f.fieldName] = {
                    id: facetValue.id,
                    value: facetValue.text,
                };
            }
        });
        return facets;
    }
    else {
        return getDefaultSelectedProperties(availableFacets);
    }
};
const getSelectableVariations = (variations, selectedProperties) => {
    const propertyNames = Object.keys(selectedProperties);
    return variations === null || variations === void 0 ? void 0 : variations.filter(variation => propertyNames.every(propertyName => { var _a; return variation[propertyName] === ((_a = selectedProperties[propertyName]) === null || _a === void 0 ? void 0 : _a.value); }));
};
const getSelectedVariationCode = (variations, availableFacets, selectedProperties, units, selectedUnitProperty) => {
    var _a, _b;
    if ((units === null || units === void 0 ? void 0 : units.length) > 0 && !selectedUnitProperty) {
        return null;
    }
    const selectableVariations = getSelectableVariations(variations, selectedProperties);
    if ((selectableVariations === null || selectableVariations === void 0 ? void 0 : selectableVariations.length) !== 1) {
        return null;
    }
    if (((_a = Object.keys(selectedProperties)) === null || _a === void 0 ? void 0 : _a.length) === availableFacets.length) {
        return selectableVariations[0].code;
    }
    const availableFacetsWithoutLength = availableFacets.filter(f => f.fieldName !== PROPERTY_NAME_LENGTH);
    if (((_b = selectedProperties.size) === null || _b === void 0 ? void 0 : _b.id) === PROPERTY_ID_SIZE_MTM &&
        availableFacetsWithoutLength.every(facet => !!selectedProperties[facet.fieldName])) {
        return selectableVariations[0].code;
    }
    return null;
};
const getDefaultSelectedUnit = (units) => {
    if ((units === null || units === void 0 ? void 0 : units.length) > 0) {
        return {
            id: units[0].key,
            value: units[0].text,
        };
    }
    return null;
};
export default function (state = defaultState, action) {
    var _a;
    switch (action.type) {
        case CLEAR_SELECTED_PROPERTIES: {
            const defaultSelectedProperties = getDefaultSelectedProperties(state.availableFacets);
            const defaultSelectedUnit = getDefaultSelectedUnit(state.units);
            return Object.assign(Object.assign({}, state), { selectedProperties: defaultSelectedProperties, latestSelectedPropertyName: null, hasSelectedProperties: false, selectedUnitProperty: defaultSelectedUnit, selectedVariationCode: getSelectedVariationCode(state.variations, state.availableFacets, defaultSelectedProperties, state.units, defaultSelectedUnit), matchingSeriesLengthProperties: [], matchingSeriesSizeProperties: [] });
        }
        case CLEAR_SELECTED_PROPERTY: {
            const newSelectedProperties = __rest(state.selectedProperties, []);
            return Object.assign(Object.assign({}, state), { latestSelectedPropertyName: null, latestSelectedPropertyTime: Date.now(), selectedProperties: newSelectedProperties });
        }
        case SELECT_PROPERTY: {
            const { id, value, propertyName: propertyName } = action;
            if (((_a = state.selectedProperties[propertyName]) === null || _a === void 0 ? void 0 : _a.id) === id) {
                return Object.assign(Object.assign({}, state), { latestSelectedPropertyTime: Date.now() });
            }
            if (propertyName === PROPERTY_NAME_UNIT) {
                const unitProperty = { id: id, value: value };
                return Object.assign(Object.assign({}, state), { selectedUnitProperty: unitProperty, latestSelectedPropertyTime: Date.now(), hasSelectedProperties: true, selectedVariationCode: getSelectedVariationCode(state.variations, state.availableFacets, state.selectedProperties, state.units, unitProperty) });
            }
            const selectedProperties = Object.assign(Object.assign({}, state.selectedProperties), { [propertyName]: { id, value } });
            return Object.assign(Object.assign({}, state), { selectedProperties: selectedProperties, latestSelectedPropertyName: propertyName, latestSelectedPropertyTime: Date.now(), hasSelectedProperties: true, selectedVariationCode: getSelectedVariationCode(state.variations, state.availableFacets, selectedProperties, state.units, state.selectedUnitProperty) });
        }
        case SET_DEFAULT_PROPERTIES: {
            const { variations, variationCode, availableFacets, units } = action;
            const defaultSelectedProperties = variationCode
                ? getSelectedVariationProperties(variationCode, variations, availableFacets)
                : getDefaultSelectedProperties(availableFacets);
            const defaultSelectedUnit = getDefaultSelectedUnit(units);
            return Object.assign(Object.assign(Object.assign({}, state), defaultState), { latestSelectedPropertyTime: Date.now(), selectedProperties: defaultSelectedProperties, variations: variations, availableFacets: availableFacets, units: units, selectedUnitProperty: defaultSelectedUnit, selectedVariationCode: getSelectedVariationCode(variations, availableFacets, defaultSelectedProperties, units, defaultSelectedUnit), hasSelectedProperties: variationCode ? true : false, initialized: true });
        }
        case SET_MATCHING_SERIES_PROPERTIES: {
            const { lengths, sizes } = action;
            return Object.assign(Object.assign({}, state), { matchingSeriesLengthProperties: lengths, matchingSeriesSizeProperties: sizes });
        }
        case PAGE_LOAD: {
            return Object.assign(Object.assign(Object.assign({}, state), defaultState), { latestSelectedPropertyTime: Date.now() });
        }
        default:
            return state;
    }
}
