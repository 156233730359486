import { appShellReducer, currentPageReducer, themeReducer, breakpointReducer, spinnerReducer, } from '@avensia/nitro5-scope';
import miniCartReducer from 'SiteLayout/MiniCart/reducer';
import wishListReducer from 'SiteLayout/WishList/reducer';
import countrySelectorReducer from 'SiteLayout/CountrySelector/reducer';
import quickOrderSearchReducer from 'Search/QuickOrderSearch/reducer';
import madeToMeasureReducer from 'Product/MadeToMeasure/reducer';
import mainMenuReducer from 'SiteLayout/MainMenu/reducer';
import quickSearchReducer from 'Search/reducer';
import noSearchResultReducer from 'Search/nohits-reducer';
import searchPageReducer from 'Search/page-reducer';
import footerReducer from 'SiteLayout/Footer/reducer';
import userAccountReducer from 'Account/reducer';
import systemTextReducer from 'Shared/SystemText/reducer';
import propertySelectorReducer from 'Product/PropertySelector/reducer';
import findRetailerReducer from 'FindRetailer/FindRetailerMap/reducer';
import findRetailerSearchReducer from 'FindRetailer/FindRetailerSearch/reducer';
import deepFreeze from './deep-freeze';
export default (state, action) => {
    const appShellData = appShellReducer(state.appShellData, action);
    // We deep freeze the state to make sure we don't accidentally mutate it
    // Note that freezing objects is quite expensive, so we only do it in
    // dev builds
    const currentPageState = searchPageReducer(currentPageReducer(state.currentPage, action, appShellData), action);
    return deepFreeze({
        appShellData,
        currentBreakpoint: breakpointReducer(state.currentBreakpoint, action, appShellData),
        currentPage: currentPageState,
        miniCart: miniCartReducer(state.miniCart, action),
        countrySelector: countrySelectorReducer(state.countrySelector, action),
        quickOrderSearch: quickOrderSearchReducer(state.quickOrderSearch, action),
        madeToMeasure: madeToMeasureReducer(state.madeToMeasure, action),
        mainMenu: mainMenuReducer(state.mainMenu, action, appShellData),
        quickSearch: quickSearchReducer(state.quickSearch, action, currentPageState),
        noSearchResult: noSearchResultReducer(state.noSearchResult, action, currentPageState),
        footer: footerReducer(state.footer, action, appShellData),
        userAccount: userAccountReducer(state.userAccount, action),
        currentTheme: themeReducer(state.currentTheme, state.appShellData, action),
        spinner: spinnerReducer(state.spinner, action),
        systemText: systemTextReducer(state.systemText, action),
        disableFlyoutTouch: disableFlyoutReducer(state.disableFlyoutTouch, action),
        wishList: wishListReducer(state.wishList, action, appShellData),
        propertySelector: propertySelectorReducer(state.propertySelector, action),
        findRetailer: findRetailerReducer(state.findRetailer, action),
        findRetailerSearch: findRetailerSearchReducer(state.findRetailerSearch, action),
    });
};
export const DISABLE_FLYOUT_TOUCH = 'DISABLE_FLYOUT_TOUCH';
export const ENABLE_FLYOUT_TOUCH = 'ENABLE_FLYOUT_TOUCH';
function disableFlyoutReducer(state = false, action) {
    switch (action.type) {
        case DISABLE_FLYOUT_TOUCH:
            return true;
        case ENABLE_FLYOUT_TOUCH:
            return false;
        default:
            return state;
    }
}
