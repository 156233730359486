import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { getPresetFromBreakpoint, imageSizes } from '../image-sizes';
import EagerImage from './Eager';
import LazyImage from './Lazy';
import { URLX, relativeUrl } from '@avensia/nitro5-scope';
import noImage from './noimage';
import { Placeholder } from './components';
import { useCurrentBreakpoint } from 'Shared/use-viewport';
export { Size as Preset } from '../image-sizes';
// ImageResizer responds with 500 error if any of the
// resolution axis exceeds this value
const IMAGERESIZER_LIMIT = 3200;
export var ImageMode;
(function (ImageMode) {
    ImageMode["padding"] = "pad";
    ImageMode["max"] = "max";
})(ImageMode || (ImageMode = {}));
export var Format;
(function (Format) {
    Format["JPG"] = "jpg";
    Format["GIF"] = "gif";
    Format["PNG"] = "png";
})(Format || (Format = {}));
// `srcSet` density descriptors
const DENSITY_DESCRIPTORS = [1.5, 2];
function Image(_a) {
    var _b;
    var { src, preset, format, lazy = true, mode, padRatio } = _a, restProps = __rest(_a, ["src", "preset", "format", "lazy", "mode", "padRatio"]);
    const breakpoint = useCurrentBreakpoint();
    const width = (_b = src) === null || _b === void 0 ? void 0 : _b.width;
    const internalPreset = preset !== null && preset !== void 0 ? preset : getPresetFromBreakpoint(breakpoint, width);
    if (!src) {
        return React.createElement(Placeholder, Object.assign({}, restProps, { src: noImage }));
    }
    if (internalPreset && !('srcSet' in restProps)) {
        const srcSetAttribute = srcSet(src.url, internalPreset, format, mode, width);
        if (srcSetAttribute) {
            restProps.srcSet = srcSetAttribute;
        }
    }
    const ImgComponent = lazy ? LazyImage : EagerImage;
    return (React.createElement(ImgComponent, Object.assign({}, restProps, { src: imageUrl(src.url, internalPreset, format, mode, undefined, padRatio), originalSrc: src.url })));
}
export default styled(Image);
function imageUrl(src, preset, format, mode, width, padRatio = 1, density = 1) {
    if (src) {
        const size = Math.round(imageSizes[preset] * density);
        if (size > IMAGERESIZER_LIMIT || size > width) {
            return null;
        }
        const url = new URLX(src);
        if (typeof preset === 'number') {
            url.searchParams.set('w', String(size));
            // Nitro has lowres images so we need to scale up images
            // to project some kind of reality
            url.searchParams.set('scale', 'both');
            if (mode) {
                url.searchParams.set('mode', mode);
                if (mode === ImageMode.padding) {
                    url.searchParams.set('h', String(Math.round(size * padRatio)));
                }
            }
        }
        if (format) {
            url.searchParams.set('format', format);
        }
        return relativeUrl(url);
    }
    return null;
}
function srcSet(src, preset, format, mode, width) {
    return DENSITY_DESCRIPTORS.map(density => {
        const url = imageUrl(src, preset, format, mode, width, undefined, density);
        if (url) {
            return `${url} ${density}x`;
        }
    })
        .filter(url => !!url)
        .join();
}
