import React, { useEffect, useRef } from 'react';
import { SmallButton } from 'Shared/Button';
import { useQuickOrderSearch } from './use-quickordersearch';
import { styled } from '@glitz/react';
import { charlie, mega, white, ZIndex, figma } from 'Shared/Style';
import { translate } from '@avensia/nitro5-scope';
import { Appearance, Basic } from 'Shared/PageLayout';
import { useIsMobile, useIsCompact } from 'Shared/use-viewport';
import QuickOrderSearch from './QuickOrderSearch';
import { useClickOutside } from 'Shared/use-click-outside';
import { useMainMenu } from 'SiteLayout/MainMenu/use-mainmenu';
import { testIdProps } from 'test-automation';
export default function QuickOrderButton() {
    const { toggleQuickOrderSearch, isOpen } = useQuickOrderSearch();
    const isCompact = useIsCompact();
    const isMobile = useIsMobile();
    const { isMainMenuOpen } = useMainMenu();
    const elementRef = useRef();
    useClickOutside(elementRef, () => {
        if (isOpen)
            toggleQuickOrderSearch();
    });
    useEffect(() => {
        if (isMobile) {
            if (isOpen)
                document.body.style.overflow = 'hidden';
            else
                document.body.style.overflow = 'unset';
        }
    }, [isMobile, isOpen]);
    return (React.createElement(Base, { ref: elementRef },
        React.createElement(Container, { css: isOpen && containerOpenStyle },
            React.createElement(StyledBasic, { appearance: [
                    Appearance.Normal,
                    !isCompact ? Appearance.StartMargin : isOpen ? Appearance.Gap : isMobile ? Appearance.Full : Appearance.Gap,
                ] },
                isOpen && !isMainMenuOpen && (React.createElement(ContentWrapper, null,
                    React.createElement(QuickOrderSearch, null))),
                React.createElement(StyledQuickOrderButton, Object.assign({}, testIdProps.OpenQuickOrderButton, { onClick: toggleQuickOrderSearch }), isOpen && !isMainMenuOpen ? translate('/Shared/Close') : translate('/QuickOrder/QuickOrder'))))));
}
const Base = styled.div({
    width: '100%',
    position: 'relative',
});
const containerOpenStyle = {
    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.07)`,
};
const Container = styled.div({
    position: 'absolute',
    width: '100%',
    backgroundColor: white,
});
const StyledBasic = styled(Basic, {
    width: '100%',
    position: 'relative',
    zIndex: ZIndex.QuickOrder,
});
const StyledQuickOrderButton = styled(SmallButton, {
    width: ({ isMobile }) => (isMobile ? '100%' : null),
    position: 'absolute',
    right: 0,
    top: 0,
    border: {
        bottom: { style: 'none' },
        radius: '0px 0px 4px 4px',
    },
    fontSize: figma,
});
const ContentWrapper = styled.div({
    position: 'relative',
    backgroundColor: 'white',
    padding: {
        top: mega,
        bottom: charlie,
    },
});
