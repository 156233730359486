import { __rest } from "tslib";
import React, { useState, useCallback } from 'react';
import { styled } from '@glitz/react';
import { isIOS, isBrowser } from '@avensia/nitro5-scope';
import { pixelsToUnit, createGeneralDecorator, createTransitionDecorator, placeholderGrey, outlineGrey, medium, figma, black05, mediaMinSmall, tiny, lightBlue, thin, black, large, stateRed, General, general, } from '../Style';
import { createTabbingFocusWithinElement, createTabbingFocusElement } from 'Shared/a11y/TabFocus';
import { media, selector } from '@glitz/core';
import { useLayoutEffect } from 'Shared/use-layout-effect';
const UNIMPORTANT_TEXT_COLOR = placeholderGrey;
const Text = createTabbingFocusWithinElement(styled((_a) => {
    var { onFocus, onBlur, onChange, onKeyDown, placeholder, children, uncontrolled, endElement, error, width, externalLabel, PlaceholderComponent = Placeholder, generalInternal = General.Default, shouldForceShowPlaceholder, required } = _a, restProps = __rest(_a, ["onFocus", "onBlur", "onChange", "onKeyDown", "placeholder", "children", "uncontrolled", "endElement", "error", "width", "externalLabel", "PlaceholderComponent", "generalInternal", "shouldForceShowPlaceholder", "required"]);
    const elementRef = React.useRef();
    const value = (restProps.value = restProps.value || '');
    const [focused, setFocused] = useState(false);
    const [, setInvalid] = useState(false);
    const [filled, setFilled] = useState(isFilled(value));
    const internalOnFocus = useCallback((e) => {
        setFocused(true);
        if (onFocus) {
            onFocus(e);
        }
    }, [setFocused, onFocus]);
    const internalOnBlur = useCallback((e) => {
        setFocused(false);
        setInvalid(isInvalid(e.currentTarget));
        if (onBlur) {
            onBlur(e);
        }
    }, [setInvalid, onBlur]);
    const internalOnChange = useCallback((e) => {
        const el = e.currentTarget;
        setInvalid(isInvalid(el));
        setFilled(isFilled(el.value));
        if (onChange) {
            onChange(e);
        }
    }, [onChange]);
    if (uncontrolled) {
        restProps.defaultValue = value;
        delete restProps.value;
    }
    useLayoutEffect(() => {
        if (uncontrolled) {
            const currentPropIsInSync = value === elementRef.current.value;
            const nextValue = value || '';
            // We don't want to allow clearing if the current value is not in sync with the current
            // prop because we might end up clearing during the time the user is typing.
            const shouldClear = nextValue === '' && currentPropIsInSync;
            if (elementRef.current && (!focused || shouldClear)) {
                elementRef.current.value = nextValue;
            }
        }
    }, [focused, uncontrolled, value]);
    function internalOnKeyDown(e) {
        if (typeof onKeyDown === 'function') {
            onKeyDown(e);
        }
    }
    const realPlaceholder = placeholder || children;
    const { disabled, readOnly } = restProps;
    const editable = !(disabled || readOnly);
    const labelDecorator = styled(Object.assign(Object.assign(Object.assign(Object.assign({ position: 'relative', backgroundColor: disabled ? black05 : theme => theme.mainBackgroundColor }, (width && { width: width })), (PlaceholderComponent === Placeholder && {
        ':focus-within': {
            border: {
                xy: {
                    color: lightBlue,
                    style: 'solid',
                    width: thin,
                },
            },
        },
    })), { border: {
            xy: {
                color: outlineGrey,
                style: 'solid',
                width: thin,
            },
        } }), (error && { border: { xy: { color: stateRed, style: 'solid', width: thin } } })));
    const generalDecorator = createGeneralDecorator(generalInternal);
    const endElementHeight = general(generalInternal).height;
    const endElementWidth = endElementHeight;
    return (React.createElement(styled.Label, { css: labelDecorator },
        externalLabel && React.createElement(ExternalLabel, null, externalLabel),
        React.createElement(Field, null,
            React.createElement(PlaceholderComponent, { css: styled(generalDecorator, filled && !shouldForceShowPlaceholder ? placeholderFilledStyle : forceShowPlaceholderStyle) }, realPlaceholder),
            React.createElement(BareInputWithoutFocusVisuals, Object.assign({}, restProps, { onFocus: internalOnFocus, onBlur: internalOnBlur, onChange: internalOnChange, ref: elementRef, required: required, onKeyDown: internalOnKeyDown, css: generalDecorator(Object.assign(Object.assign({ MozAppearance: 'textfield' }, (!editable && { color: UNIMPORTANT_TEXT_COLOR })), selector(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
                    WebkitAppearance: 'none',
                }))) })),
            endElement && (React.createElement(EndElement, { css: { width: endElementWidth, height: endElementHeight } }, endElement)))));
}, Object.assign({ display: 'inline-flex', marginBottom: 0, borderRadius: 2, color: theme => theme.mainTextColor }, createTransitionDecorator({ property: 'background' }))));
const DefaultText = Text;
export default DefaultText;
function isInvalid(el) {
    return !el.validity.valid;
}
function isFilled(value) {
    return !!value && /[\S]/.test(value);
}
const EndElement = styled(styled.Span, {
    position: 'absolute',
    right: 0,
    top: 0,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'text',
});
const Field = styled.div({
    position: 'relative',
    flex: {
        grow: 1,
        shrink: 0,
    },
    maxWidth: '100%',
});
const placeholderFilledStyle = styled({
    opacity: 0,
    pointerEvents: 'none',
});
const forceShowPlaceholderStyle = styled({ opacity: 1 });
const Placeholder = styled.span({
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    color: UNIMPORTANT_TEXT_COLOR,
    pointerEvents: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
});
export const resetInputStyled = styled({
    width: '100%',
    padding: { xy: 0 },
    border: { xy: { width: 0 } },
    color: 'inherit',
    backgroundColor: 'transparent',
    ':focus': {
        outlineWidth: 0,
    },
    outlineStyle: 'none',
    cursor: 'inherit',
});
export const BareInputWithoutFocusVisuals = styled(styled.Input, resetInputStyled, Object.assign(Object.assign({}, createTransitionDecorator({ property: 'color' })), (isBrowser() && isIOS() && { fontSize: 16 })));
export const BareInput = createTabbingFocusElement(createGeneralDecorator()(BareInputWithoutFocusVisuals));
export const FIELDS_GUTTER = medium;
const FIELDS_GUTTER_ROW = tiny;
export const Fieldset = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    margin: { top: -FIELDS_GUTTER_ROW, left: -FIELDS_GUTTER, bottom: FIELDS_GUTTER_ROW },
});
export const fieldDecorator = styled({
    flexGrow: 1,
    margin: { top: FIELDS_GUTTER_ROW, left: FIELDS_GUTTER },
});
export const InputField = styled(DefaultText, Object.assign({ textAlign: 'start', paddingTop: 20, color: black }, media(mediaMinSmall, {
    display: 'flex',
    maxWidth: 320,
})));
export const FullField = styled(DefaultText, fieldDecorator, {
    width: '100%',
});
export const MinHalfField = styled(DefaultText, fieldDecorator, {
    width: `calc(50% - ${pixelsToUnit(FIELDS_GUTTER)})`,
});
export const MinQuarterField = styled(DefaultText, fieldDecorator, {
    width: `calc(25% - ${pixelsToUnit(FIELDS_GUTTER)})`,
});
const ExternalLabel = styled.div({
    position: 'absolute',
    top: -large,
    left: 0,
    fontSize: figma - thin,
});
