import { PAGE_LOAD_FAILURE, invalidateData } from '@avensia/nitro5-scope';
import { OPEN_ACCOUNT_PANEL, CLOSE_ACCOUNT_PANEL, SET_ACCOUNT_MESSAGE, SET_ACCOUNT_PANEL_MODE, AccountPanelMode, LOGOUT_USER, } from './action-creators';
import { getCurrentUser } from 'Shared/dynamic-data';
export default function (state = null, action) {
    state = state || {
        accountPanelIsOpen: false,
        accountPanelMode: AccountPanelMode.Login,
    };
    switch (action.type) {
        case PAGE_LOAD_FAILURE:
            if (action.loadFailure.status === 401) {
                invalidateData(getCurrentUser());
            }
            return state;
        case OPEN_ACCOUNT_PANEL:
            return Object.assign({}, state, {
                accountPanelIsOpen: true,
                redirectToAfterLogin: action.redirectToAfterLogin,
            });
        case CLOSE_ACCOUNT_PANEL:
            return Object.assign({}, state, {
                accountPanelIsOpen: false,
                redirectToAfterLogin: undefined,
            });
        case SET_ACCOUNT_MESSAGE:
            return Object.assign({}, state, {
                accountMessage: action.message,
            });
        case SET_ACCOUNT_PANEL_MODE:
            return Object.assign({}, state, {
                accountPanelMode: action.mode,
            });
        case LOGOUT_USER:
            //TODO add logic
            return state;
        default:
            return state;
    }
}
