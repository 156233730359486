import React from 'react';
import connect from 'Shared/connect';
import { styled } from '@glitz/react';
import { ContentArea } from '@avensia/nitro5-scope-episerver';
import { AccountPanelMode, setAccountPanelMode, closeAccountPanel } from './action-creators';
import { translate } from '@avensia/nitro5-scope';
import { Header, Body } from 'Shared/Flyout';
import { darkWhite, FontWeight, huge, medium, white } from 'Shared/Style';
import { User } from 'Shared/Icon';
import { PlainButton } from 'Shared/Button';
import { selector } from '@glitz/core';
import { useSelector } from 'Shared/State';
import { MarketType } from 'Shared/constants';
import { testIdProps } from 'test-automation';
export default connect((state) => ({
    loginBlock: state.appShellData.loginBlock,
    registerBlock: state.appShellData.registerBlock,
    forgotPasswordBlock: state.appShellData.forgotPasswordBlock,
    accountPanelMode: state.userAccount.accountPanelMode,
}), (dispatch) => ({
    setAccountPanelMode(mode) {
        return dispatch(setAccountPanelMode(mode));
    },
    closeAccountPanel() {
        return dispatch(closeAccountPanel());
    },
}))(function Account(props) {
    const content = {
        [AccountPanelMode.Login]: React.createElement(ContentArea, Object.assign({}, props.loginBlock)),
        [AccountPanelMode.Registration]: React.createElement(ContentArea, Object.assign({}, props.registerBlock)),
        [AccountPanelMode.ForgotPassword]: React.createElement(ContentArea, Object.assign({}, props.forgotPasswordBlock)),
    }[props.accountPanelMode];
    const getHeaderText = (accountPanelMode) => {
        switch (accountPanelMode) {
            case AccountPanelMode.Login:
                return translate('/Account/Login/ButtonText');
            case AccountPanelMode.ForgotPassword:
                return translate('/Account/ForgotPassword/Caption');
            case AccountPanelMode.Registration:
                return translate('/Account/Registration/Caption');
            default:
                return '';
        }
    };
    function onLoginClick() {
        props.setAccountPanelMode(AccountPanelMode.Login);
    }
    function onRegisterClick() {
        props.setAccountPanelMode(AccountPanelMode.Registration);
    }
    const isRegistration = props.accountPanelMode === AccountPanelMode.Registration;
    const isLoginOrRegistration = props.accountPanelMode === AccountPanelMode.Login || props.accountPanelMode === AccountPanelMode.Registration;
    const marketType = useSelector(state => state.appShellData.countrySettings.marketType);
    const isB2BMarket = marketType === MarketType.B2B;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { css: isLoginOrRegistration && !isB2BMarket && headerDecorator, onClose: props.closeAccountPanel },
            (!isLoginOrRegistration || isB2BMarket) && (React.createElement(React.Fragment, null,
                React.createElement(StyledUser, null),
                React.createElement(Title, null, getHeaderText(props.accountPanelMode)))),
            isLoginOrRegistration && !isB2BMarket && (React.createElement(TabWrapper, null,
                React.createElement(Tab, { css: !isRegistration && selectedHeaderTabDecorator, onClick: onLoginClick },
                    React.createElement(StyledUser, null),
                    translate('/Account/Login/ButtonText')),
                React.createElement(Tab, Object.assign({ css: isRegistration && selectedHeaderTabDecorator, onClick: onRegisterClick }, testIdProps.OpenRegisterButton), translate('/Account/Registration/ButtonText'))))),
        React.createElement(Body, null, content)));
});
const headerDecorator = styled({
    backgroundColor: darkWhite,
});
const StyledUser = styled(User, {
    margin: { right: medium },
});
const Title = styled.span({
    fontWeight: FontWeight.SemiBold,
});
const TabWrapper = styled.div({
    display: 'flex',
    margin: { x: -huge },
});
const Tab = styled(PlainButton, Object.assign({ display: 'flex', alignItems: 'center', height: 55, padding: { x: huge }, fontWeight: FontWeight.SemiBold, flexShrink: 0 }, selector(':last-child', { width: '100%' })));
const selectedHeaderTabDecorator = styled({
    backgroundColor: white,
});
