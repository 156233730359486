import { translate } from '@avensia/nitro5-scope';
import { styled } from '@glitz/react';
import React, { useState, useRef } from 'react';
import { sourceSansPro, FontWeight } from 'Shared/Style';
import { useClickOutside } from 'Shared/use-click-outside';
import MadeToMeasureToggle from './MadeToMeasureToggle';
export default function ({ itemProps, isB2B }) {
    const { sku, pharmaCode, model, compression, color, typeOfBand, size, mtmMeasurements, hasSeriesSize, unit, leftRight, length, footType, tubeType, crotchType, } = itemProps;
    const [toggleMtm, setToggleMtm] = useState(false);
    const elementRef = useRef();
    useClickOutside(elementRef, () => {
        if (toggleMtm)
            setToggleMtm(!toggleMtm);
    });
    return (
    //Data from BE is not sorted so we need to render each component according to Figma design
    React.createElement(Additional, null,
        sku && React.createElement(Cell, null, `${translate('/Facets/sku')}: ${sku}`),
        pharmaCode && isB2B && React.createElement(Cell, null, `${translate('/Facets/pharmaCode')}: ${pharmaCode}`),
        model && React.createElement(Cell, null, `${translate('/Facets/model')}: ${model}`),
        compression && React.createElement(Cell, null, `${translate('/Facets/CCL')} : ${compression}`),
        (mtmMeasurements === null || mtmMeasurements === void 0 ? void 0 : mtmMeasurements.length) > 0 ? (React.createElement(MadeToMeasureToggle, { isOpen: toggleMtm, onToggle: () => setToggleMtm(!toggleMtm), mtmMeasurements: mtmMeasurements, elementRef: elementRef })) : (React.createElement(Cell, null, `${translate('/Facets/size')} : ${size}`)),
        color && React.createElement(Cell, null, `${translate('/Facets/color')}: ${color}`),
        typeOfBand && React.createElement(Cell, null, `${translate('/Facets/typeOfBand')}: ${typeOfBand}`),
        footType && React.createElement(Cell, null, `${translate('/Facets/footType')}: ${footType}`),
        length && React.createElement(Cell, null, `${translate('/Facets/length')}: ${length}`),
        tubeType && React.createElement(Cell, null, `${translate('/Facets/tubeType')}: ${tubeType}`),
        crotchType && React.createElement(Cell, null, `${translate('/Facets/crotchType')}: ${crotchType}`),
        leftRight && React.createElement(Cell, null, `${translate('/Cart/Additional/Unit')}: ${leftRight}`),
        unit && React.createElement(Cell, null, `${translate('/Cart/Additional/Unit')}: ${unit}`),
        hasSeriesSize && React.createElement(HighlightedCell, null, translate('/Checkout/MadeToMeasure/SeriesSizing'))));
}
const Additional = styled.div(sourceSansPro(12), {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
});
const Cell = styled.span({});
export const HighlightedCell = styled(Cell, { fontWeight: FontWeight.SemiBold });
export const Title = styled.div({
    margin: {
        xy: 0,
    },
    fontSize: 12,
});
