import { Breakpoint, isBrowser } from '@avensia/nitro5-scope';
import { useSelector } from './State';
export function useCurrentBreakpoint() {
    return useSelector(state => state.currentBreakpoint);
}
export function isMobile(currentBreakpoint) {
    return currentBreakpoint < Breakpoint.Small;
}
function isMicro(currentBreakpoint) {
    return currentBreakpoint <= Breakpoint.Micro;
}
function isTiny(currentBreakpoint) {
    return currentBreakpoint <= Breakpoint.Tiny;
}
function isLarge(currentBreakpoint) {
    return currentBreakpoint <= Breakpoint.Large;
}
// Compact includes both mobile and standing tablet
function isCompact(currentBreakpoint) {
    return currentBreakpoint <= Breakpoint.Small;
}
function isTablet(currentBreakpoint) {
    return currentBreakpoint === Breakpoint.Small;
}
function isDesktop(currentBreakpoint) {
    return currentBreakpoint >= Breakpoint.Medium;
}
function isCompactHeader(currentBreakpoint) {
    return currentBreakpoint < Breakpoint.Large;
}
export function useIsCompactHeader() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isCompactHeader(currentBreakpoint);
}
export function useIsMicro() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isMicro(currentBreakpoint);
}
export function useIsTiny() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isTiny(currentBreakpoint);
}
export function useIsLarge() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isLarge(currentBreakpoint);
}
export function useIsMobile() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isMobile(currentBreakpoint);
}
export function useIsCompact() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isCompact(currentBreakpoint);
}
export function useIsTablet() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isTablet(currentBreakpoint);
}
export function useIsDesktop() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isDesktop(currentBreakpoint);
}
let scrollbarWidth;
export function getScrollbarWidth() {
    if (isBrowser()) {
        scrollbarWidth = scrollbarWidth !== undefined ? scrollbarWidth : window.innerWidth - document.body.offsetWidth;
        return scrollbarWidth;
    }
    return 0;
}
