// These ids are meant to be used for test automation tools such as end to end testing (Selenium, Cypress, etc) but
// also for load testing tools that can record and replay a user session.
// It's important not to change or remove these unless you're absolutely sure that no such tool uses the id.
// It's highly encouraged to add new ids and then add the prop to an element (see usages of the TestIdProps object).
export const TestIds = {
    CheckoutPage: 'checkout-page',
    PickAddressButton: 'pick-address-button',
    AddressBookLine: 'address-book-line',
    MyPagesLink: 'mypages-link',
    ProductLink: 'product-link',
    CountrySelectorButton: 'country-selector-button',
    CountrySelectorModalUnderstandButton: 'country-selector-modal-understand-button',
    MainMenuTabs: 'main-menu-tabs',
    OpenRegisterButton: 'open-register-button',
    OpenLoginButton: 'open-login-button',
    ForgotPasswordButton: 'forgot-password-button',
    LoginButton: 'login-button',
    LoginFailedElement: 'login-failed-element',
    LoginEmailInput: 'login-email-input',
    LoginPasswordInput: 'login-password-input',
    LogoutButton: 'logout-button',
    RegisterButton: 'register-button',
    RegisterFailedElement: 'register-failed-element',
    RegisterFirstNameInput: 'register-first-name-input',
    RegisterLastNameInput: 'register-last-name-input',
    RegisterEmailInput: 'register-email-input',
    RegisterPasswordInput: 'register-password-input',
    RegisterAcceptTermsInput: 'register-accept-terms-input',
    B2BApplicationButton: 'b2b-application-button',
    OpenQuickOrderButton: 'open-quick-order-button',
    AddQuickOrder: 'add-quick-order',
    QuickOrderSearch: 'quick-order-search',
    MiniCartButton: 'minicart-button',
    IncreaseQuantityButton: 'increase-quantity-button',
    DecreaseQuantityButton: 'decrease-quantity-button',
    QuantityInput: 'quantity-input',
    MiniCartElement: 'minicart-element',
    CartLineItem: 'cart-line-item',
    RemoveMiniCartItemButton: 'remove-minicart-item-button',
    CloseMiniCartButton: 'close-minicart-button',
    GoToCheckoutButton: 'go-to-checkout-button',
    ProductProperty: 'product-property',
    ProductPropertyList: 'product-property-list',
    ClearSelectedOptionsBtn: 'clear-selected-options-button',
    RemoveLineItemButton: 'remove-line-item-button',
    // My pages
    CompanyNameField: 'company-name-field',
    AccountNumberField: 'account-number-field',
    TaxNumberField: 'tax-number-field',
    FirstNameField: 'first-name-field',
    LastNameField: 'last-name-field',
    EmailField: 'email-field',
    PhoneField: 'phone-field',
    PrefLanguageField: 'pref-language-field',
    GenderField: 'gender-field',
    DateOfBirthField: 'date-of-birth-field',
    SalesRepField: 'sales-rep-field',
    SalesRepEmailField: 'sales-rep-email-field',
    StandardBillingAddressField: 'standard-billing-address-field',
    DefaultBillingAddressField: 'default-billing-address-field',
    OrderHistoryTab: 'order-history-tab',
    MyAddressesTab: 'my-addresses-tab',
    OrderIdText: 'order-id-text',
    ReorderSingleLine: 'reorder-single-line',
    ReorderFullOrder: 'reorder-full-order',
    ChangePasswordTab: 'change-password-tab',
    CreateNewAddressButton: 'create-new-address-button',
    SaveAddressButton: 'save-address-button',
    EditAddressButton: 'edit-address-button',
    FilterDropdown: 'filter-dropdown',
    AddressSearchInput: 'address-search-input',
    DefaultAddressCheckbox: 'default-address-checkbox',
    DefaultAddressSection: 'default-address-section',
    DeleteAccountButton: 'delete-account-button',
    CancelAccountButton: 'cancel-account-button',
    TermsOfPurchaseCheckbox: 'terms-of-purchase-checkbox',
    CompletePurchaseButton: 'complete-purchase-button',
    SelectShippingMethodInput: 'select-shipping-method-input',
    SelectPaymentMethodInput: 'select-payment-method-input',
    VariantSelectorElement: 'variant-selector-element',
    AddToCartButton: 'add-to-cart-button',
    OpenMonitorBackInStockButton: 'open-monitor-back-in-stock-button',
    MonitorBackInStockEmailInput: 'monitor-back-in-email-input',
    MonitorBackInStockButton: 'monitor-back-in-stock-button',
    ToggleWishlistItemButton: 'toggle-wishlist-item-button',
    WishlistFlyout: 'wishlist-flyout',
    SizePropertyMtmButton: 'size-property-mtm-button',
    SaveMtmValuesButton: 'save-mtm-values-button',
    ClearMtmValuesButton: 'clear-mtm-values-button',
    ProductListSortingDropdown: 'productlist-sorting-dropdown',
    Facet: 'facet',
    FacetFilter: 'facet-filter',
    FacetExpand: 'facet-expand',
    SelectedFacet: 'selected-facet',
    TableRow: 'table-row',
    DeleteButton: 'delete-button',
    SizeGuideButton: 'size-guide-button',
    SizeGuideModal: 'size-guide-modal',
    SizeGuideCloseButton: 'size-guide-close-button',
    // Search
    SearchForm: 'search-form',
    SearchHistory: 'search-history',
    ShowArticlesButton: 'show-articles-button',
    NoResultsFoundText: 'no-results-found-text',
    SearchTopContent: 'search-top-content',
    OpenArticleButton: 'open-article-button',
    StartSearchButton: 'start-search-button',
    QuickOrderSearchField: 'quick-order-search-field',
    QuickOrderCustomerCommentField: 'quick-order-customer-comment-field',
    QuickOrderSearchResult: 'quick-order-search-result',
    QuickOrderPairDropdown: 'quick-order-pair-dropdown',
    QuickOrdeCrQuantity: 'quick-order-quantity',
    FindRetailerSearch: 'find-retailer-search',
    CallToAction: 'call-to-action',
    //Checkout
    AddDiscountButton: 'add-discount-button',
    AddDiscountInputField: 'add-discount-input-field',
    AddDiscountConfirmButton: 'add-discount-confirm-button',
    DeliveryAddressFirstName: 'delivery-address-first-name',
    DeliveryAddressLastName: 'delivery-address-last-name',
    DeliveryAddressStreet: 'delivery-address-street',
    DeliveryAddressStreetTwo: 'delivery-address-street-two',
    DeliveryAddressPostalCode: 'delivery-address-postal-code',
    DeliveryAddressCity: 'delivery-address-city',
    DeliveryAddressCountry: 'delivery-address-country',
    DeliveryAddressProvince: 'delivery-address-province',
    DeliveryAddressEmail: 'delivery-address-email',
    DeliveryAddressPhone: 'delivery-address-phone',
    AltAddressCheckBox: 'alt-address-check-box',
    AltAddressFirstName: 'alt-address-first-name',
    AltAddressLastName: 'alt-address-last-name',
    AltAddressStreet: 'alt-address-street',
    AltAddressStreetTwo: 'alt-address-street-two',
    AltAddressPostalCode: 'alt-address-postal-code',
    AltAddressCity: 'alt-address-city',
    AltAddressCountry: 'alt-address-country',
    AltAddressProvince: 'alt-address-province',
};
export const testIdAttribute = 'data-test-id';
export const testIdProps = {};
let key;
for (key in TestIds) {
    testIdProps[key] = testId(key);
}
export function testId(id) {
    return { [testIdAttribute]: TestIds[id] };
}
