import { __rest } from "tslib";
import React, { createContext, useContext, useMemo } from 'react';
import { styled } from '@glitz/react';
import { widthFromBreakpoint } from '@avensia/nitro5-scope';
import useAppearance from 'Shared/use-appearance';
import * as style from 'Shared/Style';
import { gigantic, margin, medium, small, huge, fullViewportWidthBlock } from 'Shared/Style';
import { cleanAppearance } from './cleanAppearance';
export var Appearance;
(function (Appearance) {
    /** Content is stretched to the edges if viewport with is less than max page width */
    Appearance[Appearance["Narrow"] = 0] = "Narrow";
    Appearance[Appearance["Normal"] = 1] = "Normal";
    Appearance[Appearance["Wide"] = 2] = "Wide";
    Appearance[Appearance["Gap"] = 3] = "Gap";
    Appearance[Appearance["Part"] = 4] = "Part";
    Appearance[Appearance["Nav"] = 5] = "Nav";
    Appearance[Appearance["Default"] = 6] = "Default";
    Appearance[Appearance["StandardContentPage"] = 7] = "StandardContentPage";
    Appearance[Appearance["ContentPage"] = 8] = "ContentPage";
    Appearance[Appearance["Full"] = 9] = "Full";
    Appearance[Appearance["StartMargin"] = 10] = "StartMargin";
    Appearance[Appearance["CheckoutPage"] = 11] = "CheckoutPage";
    Appearance[Appearance["ProductsBlock"] = 12] = "ProductsBlock";
})(Appearance || (Appearance = {}));
export var Layout;
(function (Layout) {
    Layout[Layout["None"] = 0] = "None";
    Layout[Layout["OneToOne"] = 1] = "OneToOne";
    Layout[Layout["TwoToThree"] = 2] = "TwoToThree";
    Layout[Layout["ThreeToTwo"] = 3] = "ThreeToTwo";
    Layout[Layout["OneToFour"] = 4] = "OneToFour";
    Layout[Layout["FourToOne"] = 5] = "FourToOne";
})(Layout || (Layout = {}));
// Gives a layout based on visual order for wider windows like desktop,
// but sorts columns by ratio descendingly so the greatest ratio will be
// the first child and the less ratio will be the last
//
// E.g. `[3, 5, 2]` will display a layout like:
// ________________________
// |30%___|50%_______|20%_|
//
// But DOM order and visual order för narrower windows will be:
// ________________________
// |50%_______|30%___|20%_|
function entity(fractions) {
    return {
        fractions,
        orders: fractions
            .map((fraction, index) => [index + 1, fraction])
            .sort((a, b) => b[1] - a[1])
            .map(x => x[0]),
    };
}
const entities = {
    [Layout.OneToOne]: entity([1, 1]),
    [Layout.TwoToThree]: entity([2, 3]),
    [Layout.ThreeToTwo]: entity([3, 2]),
    [Layout.OneToFour]: entity([1, 4]),
    [Layout.FourToOne]: entity([4, 1]),
};
const Context = createContext({ hasColumnGap: false, hasRowGap: false });
export function factory(Component, defaults = []) {
    return styled(function PageLayout(_a) {
        var { appearance, layout, elementRef, children, keepOrder } = _a, restProps = __rest(_a, ["appearance", "layout", "elementRef", "children", "keepOrder"]);
        const { hasColumnGap, hasRowGap } = useContext(Context);
        // clean appearance array from duplicates, null, false and undefined
        const cleanedAppearance = cleanAppearance([].concat(defaults, appearance));
        const appear = useAppearance(defaults.concat(cleanedAppearance));
        const applyColumnGap = appear(Appearance.Gap) && !hasColumnGap;
        const applyRowGap = appear(Appearance.Part) && !hasRowGap;
        const applyNoGap = appear(Appearance.Part) && !hasRowGap;
        const applyStartMargin = appear(Appearance.StartMargin);
        const nextContext = useMemo(() => {
            return {
                hasColumnGap: applyColumnGap,
                hasRowGap: applyRowGap,
                hasStartMargin: applyStartMargin,
            };
        }, [applyColumnGap, applyRowGap, applyStartMargin]);
        let passChildren = children;
        if (typeof layout === 'number') {
            if (layout === Layout.None) {
                // Wrap all children with an element when this layout is used to
                // prevent unmount/mount whenever there's a conditional layout
                passChildren = React.Children.map(children, child => React.createElement(styled.Div, null, child));
            }
            else {
                const { orders } = entities[layout];
                passChildren = React.Children.map(children, (child, i) => {
                    if (keepOrder) {
                        return React.createElement(styled.Div, { css: { order: i } }, child);
                    }
                    if (orders[i]) {
                        return React.createElement(styled.Div, { css: { order: orders[i] } }, child);
                    }
                    if (__DEV__) {
                        console.warn(`Child index ${i} was not rendered because it exceeded the number of allowed children of ${entity.length}`);
                    }
                });
            }
        }
        return (React.createElement(Context.Provider, { value: nextContext },
            React.createElement(Component, Object.assign({}, restProps, { ref: elementRef, css: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ margin: { x: 'auto' }, maxWidth: '100%', width: '100%' }, (appear(Appearance.Narrow) && {
                    width: widthFromBreakpoint(style.pageNarrowBreakpoint),
                })), (appear(Appearance.Normal) && {
                    width: widthFromBreakpoint(style.pageNormalBreakpoint),
                })), (appear(Appearance.Wide) && {
                    width: widthFromBreakpoint(style.pageWideBreakpoint),
                })), (appear(Appearance.CheckoutPage) && {
                    width: 1200,
                })), (appear(Appearance.ProductsBlock) && {
                    width: 1120,
                })), (appear(Appearance.StandardContentPage) && {
                    width: 768,
                })), (appear(Appearance.ContentPage) && {
                    width: 1080,
                })), (appear(Appearance.Default) && {
                    width: widthFromBreakpoint(style.pageDefaultBreakpoint) + gigantic + medium,
                })), (appear(Appearance.Full) && Object.assign({}, fullViewportWidthBlock))), ((applyColumnGap || applyRowGap || applyStartMargin) && Object.assign(Object.assign(Object.assign(Object.assign({}, (applyColumnGap && {
                    maxWidth: theme => `calc(100vw - ${style.pixelsToUnit(margin.large(theme) * 2)})`,
                })), (applyStartMargin && {
                    maxWidth: theme => `calc(100vw - ${style.pixelsToUnit(margin.large(theme) * 5 + small)} )`,
                })), (applyRowGap && {
                    marginBottom: margin.huge,
                })), (applyNoGap && {
                    marginBotttom: 0,
                })))), (typeof layout === 'number' &&
                    layout !== Layout.None && {
                    // Layouts when `isCompact === true` are stacked below each other
                    display: 'grid',
                    gridColumnGap: theme => (theme.isCompact ? undefined : style.pixelsToUnit(margin.large(theme) + huge)),
                    gridTemplateColumns: ({ isCompact }) => isCompact ? '1fr' : entities[layout].fractions.map(fraction => `${fraction}fr`).join(' '),
                })) }), passChildren)));
    });
}
export const Basic = factory(styled.Div);
export const Part = factory(styled.Div, [Appearance.Part]);
export const Section = factory(styled.Section);
export const Aside = factory(styled.Aside);
export const Article = factory(styled.Article);
export const Form = factory(styled.Form);
export const Main = factory(styled.Main);
