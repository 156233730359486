import { Breakpoint, widthFromBreakpoint } from '@avensia/nitro5-scope';
import { useCurrentBreakpoint } from './use-viewport';
export var Size;
(function (Size) {
    /** Image preset of 60px width */
    Size[Size["Thumb"] = 0] = "Thumb";
    /** Image preset of 100px width */
    Size[Size["Micro"] = 1] = "Micro";
    /** Image preset of 320px width */
    Size[Size["Tiny"] = 2] = "Tiny";
    /** Image preset of 250px width */
    Size[Size["Mini"] = 3] = "Mini";
    /** Image preset of 640px width */
    Size[Size["Small"] = 4] = "Small";
    /** Image preset of 960px width */
    Size[Size["Medium"] = 5] = "Medium";
    /** Image preset of 1280px width */
    Size[Size["Large"] = 6] = "Large";
    /** Image preset of 1920px width */
    Size[Size["Huge"] = 7] = "Huge";
    /** Image preset of 3200px width */
    Size[Size["Gigantic"] = 8] = "Gigantic";
})(Size || (Size = {}));
export const imageSizes = {
    [Size.Thumb]: 60,
    [Size.Micro]: 100,
    [Size.Mini]: 250,
    [Size.Tiny]: 320,
    [Size.Small]: 640,
    [Size.Medium]: 960,
    [Size.Large]: 1280,
    [Size.Huge]: 1920,
    [Size.Gigantic]: 3200,
};
export function getPresetFromWidth(estimatedWidth, availableSizes = imageSizes) {
    return parseInt(Object.keys(availableSizes).find(sizeKey => availableSizes[+sizeKey] >= estimatedWidth), 10);
}
function widthFromBreakpointSafe(breakpoint) {
    const breakpointKeys = Object.keys(Breakpoint)
        .map(Number)
        .filter(k => typeof k === 'number' && !isNaN(k));
    const highestBreakpoint = breakpointKeys[breakpointKeys.length - 1];
    return widthFromBreakpoint(Math.min(breakpoint, highestBreakpoint));
}
export function getPresetFromBreakpoint(breakpoint, width) {
    const maxWidthFromBreakpoint = widthFromBreakpointSafe(breakpoint + 1);
    if (typeof width === 'number' && maxWidthFromBreakpoint > width) {
        return null;
    }
    return getPresetFromWidth(maxWidthFromBreakpoint, imageSizes);
}
export function usePresetForShareOfViewport(sharePercent) {
    const breakpoint = useCurrentBreakpoint();
    if (sharePercent === 100 || breakpoint <= Breakpoint.Small) {
        return getPresetFromBreakpoint(breakpoint);
    }
    const maxWithFromBreakpoint = widthFromBreakpointSafe(breakpoint + 1);
    const imageWidthForShare = imageSizes[getPresetFromWidth(maxWithFromBreakpoint * (sharePercent / 100))];
    return getPresetFromWidth(imageWidthForShare);
}
