import React, { useRef, forwardRef } from 'react';
import { styled } from '@glitz/react';
import { Motion, spring } from 'react-motion';
import { itemKey, convertUrlToId, isDummyChildOnly } from './item-helpers';
import Level from './Level';
import { huge, outlineLightGrey, thin, black05, dirtyWhite, hoverGrey, FontWeight, medium } from 'Shared/Style';
import { WhiteThemeBlock } from 'Shared/Theme';
import { translate } from '@avensia/nitro5-scope';
import { useMainMenu } from './use-mainmenu';
import { TabTrap } from 'Shared/a11y/TabTrap';
import { PlainButton } from 'Shared/Button';
import { createFocusOnlyVisibleElement } from 'Shared/a11y/TabFocus';
import { selector } from '@glitz/core';
import { TextPlaceholder } from 'Shared/TextPlaceholder';
const headerHighlightDecorator = styled({
    color: ({ siteTheme }) => siteTheme.mainMenu.headerHighlightColor,
});
const backgroundHighlightDecorator = styled({
    backgroundColor: black05,
});
const MainMenu = styled(forwardRef((props, ref) => {
    const { isMainMenuOpen, openMenuItem, closeMainMenu } = useMainMenu();
    // Preserve last open menu items during animation
    const lastOpenMenuItemRef = useRef();
    if (isMainMenuOpen) {
        lastOpenMenuItemRef.current = openMenuItem;
    }
    const motionStyle = {
        translateY: spring(isMainMenuOpen ? 0 : -40),
        opacity: spring(isMainMenuOpen ? 1 : 0),
    };
    return (React.createElement(Motion, { style: motionStyle }, (interpolatedStyle) => {
        var _a;
        return (React.createElement(Base, { elementRef: ref, css: {
                pointerEvents: isMainMenuOpen ? 'auto' : 'none',
                boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
            }, style: interpolatedStyle.translateY !== 0
                ? { opacity: interpolatedStyle.opacity, transform: `translateY(${interpolatedStyle.translateY}px)` }
                : null, tabIndex: props.tabIndex }, lastOpenMenuItemRef.current && !props.closeMenu && (React.createElement(TabTrap, { enabled: isMainMenuOpen && !props.closeMenu },
            React.createElement("nav", { "aria-labelledby": convertUrlToId(lastOpenMenuItemRef.current.url) },
                React.createElement(CloseButtonForA11y, { onClick: closeMainMenu }, translate('/Shared/Close')),
                React.createElement(Categories, null,
                    React.createElement(FirstLevel, { css: lastOpenMenuItemRef.current.isFirstRowHighlight && backgroundHighlightDecorator },
                        lastOpenMenuItemRef.current.firstRowHeadline && (React.createElement(Headline, { css: lastOpenMenuItemRef.current.isFirstRowHighlight && headerHighlightDecorator }, lastOpenMenuItemRef.current.firstRowHeadline)),
                        isDummyChildOnly(lastOpenMenuItemRef.current) ? (React.createElement(React.Fragment, null,
                            React.createElement(TextPlaceholderStyled, { rowCount: 3, Component: styled.Li }),
                            React.createElement(TextPlaceholderStyled, { rowCount: 3, Component: styled.Li }),
                            React.createElement(TextPlaceholderStyled, { rowCount: 3, Component: styled.Li }),
                            React.createElement(TextPlaceholderStyled, { rowCount: 3, Component: styled.Li }))) : (lastOpenMenuItemRef.current.children.map((menuItem, index) => {
                            if (menuItem) {
                                return React.createElement(Level, { key: itemKey(menuItem, index), data: menuItem });
                            }
                        }))),
                    ((_a = lastOpenMenuItemRef.current.secondRowChildren) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement(FirstLevel, { css: lastOpenMenuItemRef.current.isSecondRowHighlight && backgroundHighlightDecorator },
                        lastOpenMenuItemRef.current.secondRowHeadline && (React.createElement(Headline, { css: lastOpenMenuItemRef.current.isSecondRowHighlight && headerHighlightDecorator }, lastOpenMenuItemRef.current.secondRowHeadline)),
                        isDummyChildOnly(lastOpenMenuItemRef.current) ? (React.createElement(React.Fragment, null,
                            React.createElement(TextPlaceholderStyled, { rowCount: 3, Component: styled.Li }),
                            React.createElement(TextPlaceholderStyled, { rowCount: 3, Component: styled.Li }),
                            React.createElement(TextPlaceholderStyled, { rowCount: 3, Component: styled.Li }),
                            React.createElement(TextPlaceholderStyled, { rowCount: 3, Component: styled.Li }))) : (lastOpenMenuItemRef.current.secondRowChildren.map((menuItem, index) => {
                            if (menuItem) {
                                return React.createElement(Level, { key: itemKey(menuItem, index), data: menuItem });
                            }
                        }))))))))));
    }));
}));
export default MainMenu;
const FirstLevel = styled.div({
    display: 'flex',
    margin: { x: 32, top: 16 },
    padding: { xy: 16 },
    borderRadius: 8,
});
const Base = styled(WhiteThemeBlock, {
    width: '100%',
    opacity: 1,
    border: {
        top: {
            color: outlineLightGrey,
            width: thin,
            style: 'solid',
        },
    },
    marginLeft: -huge,
});
const Categories = styled.ul(Object.assign(Object.assign(Object.assign({ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', margin: { x: 0, top: 0, bottom: medium }, paddingLeft: 0, overflowY: 'auto', maxHeight: `calc(90vh - 20px)` }, selector('::-webkit-scrollbar', {
    width: 7,
})), selector('::-webkit-scrollbar-thumb', {
    backgroundColor: hoverGrey,
    borderRadius: 10,
    margin: {
        x: 12,
        y: 10,
    },
})), selector('::-webkit-scrollbar-track', {
    backgroundColor: dirtyWhite,
    borderRadius: 10,
    margin: { x: 12 },
    ':first-child': {
        margin: {
            y: 10,
        },
    },
})));
const CloseButtonForA11y = createFocusOnlyVisibleElement(styled(PlainButton, { position: 'absolute', top: 0 }));
const Headline = styled.div({
    display: 'block',
    fontWeight: FontWeight.SemiBold,
    padding: { x: 8, top: 24 },
    marginRight: 60,
    flex: {
        basis: 150,
        grow: 0,
        shrink: 0,
    },
});
const TextPlaceholderStyled = styled(TextPlaceholder, { breakInside: 'avoid', listStyleType: 'none' });
