import React from 'react';
import { styled } from '@glitz/react';
import { selector } from '@glitz/core';
import { translate } from '@avensia/nitro5-scope';
import { average, white, FontWeight, tiny } from 'Shared/Style';
import { BareButton } from 'Shared/Button';
import { ArrowDown, ArrowUp } from 'Shared/Icon';
function getPosition(position) {
    switch (position.toLowerCase()) {
        case 'left':
            return translate('/Checkout/MadeToMeasure/LeftLeg');
        case 'right':
            return translate('/Checkout/MadeToMeasure/RightLeg');
        default:
            return '';
    }
}
function MadeToMeasureToggle({ isOpen, mtmMeasurements, onToggle, elementRef }) {
    const sortOrder = [
        'LT',
        'LH',
        'LG',
        'LF',
        'LE',
        'LD',
        'LC',
        'LB1',
        'LB',
        'LA',
        'LZ',
        'CT',
        'CH',
        'CG',
        'CF',
        'CE',
        'CD',
        'CC',
        'CB1',
        'CB',
        'CY',
        'CA',
    ];
    const sortedMeasurements = mtmMeasurements.sort((a, b) => {
        if (a.key === b.key) {
            return a.position.localeCompare(b.position);
        }
        else {
            return sortOrder.indexOf(a.key) - sortOrder.indexOf(b.key);
        }
    });
    const mtmDropdown = sortedMeasurements === null || sortedMeasurements === void 0 ? void 0 : sortedMeasurements.map((item, key) => {
        return (React.createElement(DropdownBase, { key: key },
            React.createElement(LabelTitle, null, `${item.displayName} ${getPosition(item.position)}:  `),
            React.createElement(Label, null, item.value)));
    });
    return (React.createElement(Base, null,
        React.createElement(BareButton, { onClick: onToggle },
            `${translate('/Facets/size')}: ${translate('/Checkout/MadeToMeasure/ShowMeasurements')}`,
            React.createElement(ArrowWrapper, null, isOpen ? React.createElement(ArrowUp, null) : React.createElement(ArrowDown, null))),
        isOpen && React.createElement(MtmBody, { ref: elementRef }, mtmDropdown)));
}
export default MadeToMeasureToggle;
const Base = styled.div({
    position: 'relative',
});
const DropdownBase = styled.div({
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    fontSize: 14,
});
const ArrowWrapper = styled.span({ marginLeft: tiny });
const MtmBody = styled.div(Object.assign(Object.assign(Object.assign(Object.assign({ maxHeight: 300, overflowY: 'auto' }, selector('::-webkit-scrollbar', { width: 8 })), selector('::-webkit-scrollbar-thumb', { backgroundColor: '#A5A5A5', borderRadius: 10 })), selector('::-webkit-scrollbar-track', { backgroundColor: '#F2F2F2', borderRadius: 10, margin: { xy: average } })), { boxShadow: '2px 3px 16px rgba(0 ,0 ,0, 0.1)', padding: { top: average, x: average, bottom: 0 }, position: 'absolute', width: 220, backgroundColor: white, zIndex: 2, borderRadius: 4, top: 16 }));
const LabelTitle = styled.div({
    fontWeight: FontWeight.Bold,
    flexBasis: '80%',
});
const Label = styled.div({
    justifyContent: 'self-end',
    marginBottom: 16,
    flexBasis: '50%',
});
