import React, { useRef, useState } from 'react';
import { styled } from '@glitz/react';
import { DynamicData, translate } from '@avensia/nitro5-scope';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
import { useSelector } from 'Shared/State';
import Logotype from 'Shared/Logotype';
import Link from 'Shared/Link';
import Overlay from 'Shared/Overlay';
import UserButton from './UserButton';
import CartButton from './CartButton';
import CountrySelectorButton from './CountrySelectorButton';
import { medium, large, tiny, white, tooltipColor, thin, ZIndex } from 'Shared/Style';
import QuickSearch from 'Search/QuickSearch';
import { errorMessageStyled } from 'Shared/FeedbackMessage';
import { WhiteThemeBlock } from 'Shared/Theme';
import { Contact, FindRetailer, Store } from 'Shared/Icon';
import { LinkStyledButton } from 'Shared/Button';
import { useMainMenu } from 'SiteLayout/MainMenu/use-mainmenu';
import Navbar from 'SiteLayout/MainMenu/Navbar';
import { HeaderContext } from './HeaderContext';
import { contactUsPageUrl, findRetailerUrl, dealerPortalPageUrl } from 'Shared/known-urls';
import { getCurrentUser } from 'Shared/dynamic-data';
import WishList from './Wishlist';
import { HEADER_HEIGHT_FULL } from './use-header-height';
import QuickOrderButton from 'Search/QuickOrderSearch';
import { MarketType } from 'Shared/constants';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
import { addToDataLayer, FindRetailerId, GA4FindRetailer } from 'TrackingInformation';
import { createFindRetailerClickGa4 } from 'TrackingInformation/ga4';
import CTAButton from './CTAButton';
export default function FullHeader({ quickOrderEnabled }) {
    const [isHovered, setIsHovered] = useState(false);
    const [tooltipRef, setTooltipRef] = useState(null);
    const isMainMenuOpen = useSelector(state => state.mainMenu.mainMenuOpen);
    const { closeMainMenu, homeUrl } = useMainMenu();
    const marketType = useSelector(state => state.appShellData.countrySettings.marketType);
    const canUsePurchaseActions = useSelector(state => state.appShellData.canUsePurchaseActions);
    const isCheckoutPage = useSelector(state => currentPageIsCheckout(state.currentPage));
    const hideWishList = useSelector(state => state.appShellData.siteSettings.hideWishListIcon);
    const hideContact = useSelector(state => state.appShellData.siteSettings.hideContactIcon);
    const hideLanguageSelector = useSelector(state => state.appShellData.siteSettings.hideLanguageSelectorIcon);
    const isFindRetailerExternal = useSelector(state => state.appShellData.siteSettings.isFindRetailerPageExternal);
    const mainMenuMountElement = useRef();
    function onMouseEnter(e) {
        setTooltipRef(e.currentTarget.id);
        setIsHovered(true);
    }
    function onMouseLeave(e) {
        setIsHovered(false);
    }
    function handleFindRetailerUrl() {
        addToDataLayer(createFindRetailerClickGa4(GA4FindRetailer.HEADER));
    }
    return (React.createElement(HeaderContext.Provider, { value: { mainMenuMountElement } },
        React.createElement(Overlay, { Component: styled.Header, enabled: isMainMenuOpen, onClose: closeMainMenu },
            React.createElement(NoScript, null, translate('/Errors/JavaScriptRequired')),
            React.createElement(WhiteThemeBlock, null,
                React.createElement(Base, { elementRef: mainMenuMountElement, appearance: [PageAppearance.Nav] },
                    React.createElement(React.Fragment, null,
                        React.createElement(PrimaryNavigation, null,
                            React.createElement(Link, { to: homeUrl, "aria-label": translate('/Shared/GoToStartpage'), css: __$hoisted_o0 },
                                React.createElement(Logotype, { maxWidth: 200, maxHeight: 50 })),
                            React.createElement(Navbar, null)),
                        React.createElement(SecondaryNavigation, null,
                            React.createElement(styled.Div, { id: "menu-wrapper", css: __$hoisted_o1 },
                                React.createElement(Search, null),
                                dealerPortalPageUrl() && (React.createElement(Tooltip, { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, id: "store" },
                                    React.createElement(LinkStyledButton, { to: dealerPortalPageUrl(), target: "_blank" },
                                        React.createElement(Store, null)),
                                    isHovered && tooltipRef === 'store' && (React.createElement(TooltipText, null, translate('/MainMenu/DealerPortal'))))),
                                findRetailerUrl() && (React.createElement(Tooltip, { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, id: "findRetailer" },
                                    React.createElement(LinkStyledButton, { onClick: handleFindRetailerUrl, to: findRetailerUrl(), id: FindRetailerId.GEO_LOCATION_BUTTON, target: isFindRetailerExternal ? '_blank' : '_self' },
                                        React.createElement(FindRetailer, null)),
                                    isHovered && tooltipRef === 'findRetailer' && (React.createElement(TooltipText, null, translate('/MainMenu/FindRetailer'))))),
                                !hideWishList && marketType !== MarketType.NO_COMMERCE && (React.createElement(Tooltip, { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, id: "wishlist" },
                                    React.createElement(WishList, null),
                                    isHovered && tooltipRef === 'wishlist' && (React.createElement(TooltipText, null, translate('/Shared/Favorites'))))),
                                !hideContact && (React.createElement(Tooltip, { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, id: "contact" },
                                    React.createElement(LinkStyledButton, { to: contactUsPageUrl() },
                                        React.createElement(Contact, null)),
                                    isHovered && tooltipRef === 'contact' && (React.createElement(TooltipText, null, translate('/MainMenu/ContactUs'))))),
                                marketType !== MarketType.NO_COMMERCE && (React.createElement(Tooltip, { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, id: "login" },
                                    React.createElement(UserButton, null),
                                    React.createElement(DynamicData, { data: getCurrentUser(), fallbackData: {
                                            isLoggedIn: false,
                                            id: undefined,
                                            email: undefined,
                                            fullName: undefined,
                                            isB2B: false,
                                        } }, currentUser => (React.createElement(React.Fragment, null, currentUser.isLoggedIn
                                        ? isHovered &&
                                            tooltipRef === 'login' && React.createElement(TooltipText, null, translate('/MainMenu/MyPages'))
                                        : isHovered &&
                                            tooltipRef === 'login' && React.createElement(TooltipText, null, translate('/MainMenu/Login'))))))),
                                canUsePurchaseActions && !isCheckoutPage && (React.createElement(Tooltip, { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, id: "cart" },
                                    React.createElement(CartButton, null),
                                    isHovered && tooltipRef === 'cart' && React.createElement(TooltipText, null, translate('/MainMenu/MyCart')))),
                                !hideLanguageSelector && React.createElement(CountrySelectorButton, null)),
                            React.createElement(CTAButton, null))))),
            quickOrderEnabled && (React.createElement(WhiteThemeBlock, null,
                React.createElement(QuickOrderButton, null))))));
}
const NoScript = styled(styled.Noscript, errorMessageStyled, { textAlign: 'center' });
const Base = styled(Basic, {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: { x: '2rem' },
    height: HEADER_HEIGHT_FULL,
    maxWidth: '100%',
});
const PrimaryNavigation = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '0.5rem',
});
const Search = styled(QuickSearch, {
    marginLeft: medium,
    marginRight: large / 2,
});
const SecondaryNavigation = styled.nav({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
});
const Tooltip = styled.div({
    position: 'relative',
    display: 'inline-block',
});
const TooltipText = styled.span({
    visibility: 'visible',
    width: '120px',
    backgroundColor: tooltipColor,
    color: white,
    textAlign: 'center',
    borderRadius: tiny + thin,
    padding: { xy: tiny },
    /* Position the tooltip */
    position: 'absolute',
    zIndex: ZIndex.Tooltip,
    top: '140%',
    marginLeft: '-85%',
    '::after': {
        content: '""',
        position: 'absolute',
        bottom: '100%',
        left: '50%',
        color: 'inherit',
        marginLeft: '-5px',
        border: {
            xy: { width: '5px', style: 'solid' },
            bottom: { color: tooltipColor },
            x: { color: 'transparent' },
            top: { color: 'transparent' },
        },
    },
});
const __$hoisted_o0 = { margin: { y: 0, x: 'auto' }, padding: { right: 0, y: 19 } };
const __$hoisted_o1 = {
    display: 'flex',
    alignItems: 'center',
};
