import { __awaiter } from "tslib";
import React, { useState, useCallback } from 'react';
import Flyout, { Position, Body as FlyoutBody, Header } from 'Shared/Flyout';
import { clearContentCaches, currentUrl, translate, URLX, useDynamicData } from '@avensia/nitro5-scope';
import { Theme, DefaultButton, LinkButton } from 'Shared/Button';
import { styled } from '@glitz/react';
import { average, createGeneralDecorator, FontWeight, General, medium, primary, small, black05 } from 'Shared/Style';
import { useSelector } from 'Shared/State';
import { useDispatch } from 'react-redux';
import { toggleCountrySelector } from './action-creators';
import { FlagIconAustralia, FlagIconAustria, FlagIconBarsil, FlagIconCanada, FlagIconChina, FlagIconFrance, FlagIconGermany, FlagIconGlobe, FlagIconItaly, FlagIconMexico, FlagIconOther, FlagIconPoland, FlagIconRussia, FlagIconSwitzerland, FlagIconUnitedKingdom, FlagIconUnitedStates, Global, } from 'Shared/Icon';
import Modal from 'Shared/Modal';
import { A, H4, P } from 'Shared/Generic';
import { resetLinkDecorator } from 'Shared/Generic/A';
import { logout } from 'Account/action-creators';
import { getCurrentUser } from 'Shared/dynamic-data';
import { testIdProps } from '../../test-automation';
export default function CountrySelector() {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.countrySelector.isOpen);
    const toggle = useCallback(() => {
        dispatch(toggleCountrySelector());
    }, [dispatch]);
    const currentUser = useDynamicData(getCurrentUser());
    const availableLanguages = useSelector(state => state.appShellData.availableLanguages);
    const currentCountry = useSelector(state => state.appShellData.currentCountry.alpha3);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [countryClicked, setCountryClicked] = useState(null);
    const getUrl = useCallback((lang, redirectToUrl) => {
        const url = redirectToUrl ? new URLX(redirectToUrl) : currentUrl();
        url.searchParams.set('lang', lang === null || lang === void 0 ? void 0 : lang.culture);
        url.searchParams.set('market', lang === null || lang === void 0 ? void 0 : lang.market);
        url.searchParams.set('ssw', '1');
        return url;
    }, []);
    const changeMarket = useCallback((lang, redirectToUrl) => __awaiter(this, void 0, void 0, function* () {
        yield clearContentCaches();
        window.location.href = getUrl(lang, redirectToUrl).toString();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);
    const handleOnClickCountry = useCallback((language, clickedCountry, shouldLogout) => {
        const IS_SAME_COUNTRY = clickedCountry.toLowerCase() === currentCountry.toLowerCase();
        if (isOpen) {
            toggle();
        }
        if (IS_SAME_COUNTRY || !(currentUser === null || currentUser === void 0 ? void 0 : currentUser.isLoggedIn)) {
            setIsModalOpen(false);
            changeMarket(language);
        }
        else {
            IS_SAME_COUNTRY && (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isLoggedIn) ? setIsModalOpen(false) : setIsModalOpen(true);
            if (shouldLogout) {
                setIsModalOpen(false);
                logout(getUrl(language, '/').toString(), true);
            }
        }
    }, [changeMarket, currentCountry, currentUser === null || currentUser === void 0 ? void 0 : currentUser.isLoggedIn, getUrl, isOpen, toggle]);
    return (React.createElement(React.Fragment, null,
        isModalOpen && (React.createElement(Modal, { isOpen: isModalOpen, hideCloseIcon: true, onClose: () => {
                setIsModalOpen(false);
            } },
            React.createElement(ModalContainer, null,
                React.createElement(StyledHeading, null, translate('/CountryLanguageSelector/Modal/Header')),
                React.createElement(P, null, translate('/CountryLanguageSelector/Modal/Text')),
                React.createElement(StyledButton, Object.assign({ as: Theme.Primary, onClick: () => {
                        handleOnClickCountry(countryClicked, '', true);
                    } }, testIdProps.CountrySelectorModalUnderstandButton), translate('/CountryLanguageSelector/Modal/Agree')),
                React.createElement(LinkButton, { onClick: () => {
                        setIsModalOpen(false);
                    }, css: __$hoisted_o0 }, translate('/CountryLanguageSelector/Modal/Cancel'))))),
        React.createElement(Flyout, { position: Position.Right, onClose: toggle, isOpen: isOpen },
            React.createElement(Header, { onClose: toggle },
                React.createElement(Global, null),
                React.createElement(Title, null, translate('/Shared/SelectLocation'))),
            React.createElement(Body, null, availableLanguages.map(language => (React.createElement(FullButton, { key: language.culture, id: language.market, onClick: (e) => {
                    e.preventDefault();
                    if (isOpen) {
                        setCountryClicked(language);
                        handleOnClickCountry(language, language.market, false);
                    }
                }, to: language.url },
                getFlagIconForLanguage(language.culture, language.flagOverride),
                React.createElement(CultureName, null, language.name))))))));
}
export const getFlagIconForLanguage = (culture, flagOverride) => {
    const flagId = flagOverride && flagOverride.length > 0 ? flagOverride : culture;
    switch (flagId) {
        case 'en':
            return React.createElement(FlagIconUnitedKingdom, null);
        case 'en-AU':
            return React.createElement(FlagIconAustralia, null);
        case 'de-AT':
            return React.createElement(FlagIconAustria, null);
        case 'pt-BR':
            return React.createElement(FlagIconBarsil, null);
        case 'en-CA':
        case 'fr-CA':
            return React.createElement(FlagIconCanada, null);
        case 'zh':
        case 'zh-CN':
            return React.createElement(FlagIconChina, null);
        case 'fr-FR':
            return React.createElement(FlagIconFrance, null);
        case 'de-DE':
            return React.createElement(FlagIconGermany, null);
        case 'it-IT':
            return React.createElement(FlagIconItaly, null);
        case 'es-MX':
            return React.createElement(FlagIconMexico, null);
        case 'pl-PL':
            return React.createElement(FlagIconPoland, null);
        case 'ru-RU':
            return React.createElement(FlagIconRussia, null);
        case 'de-CH':
        case 'fr-CH':
            return React.createElement(FlagIconSwitzerland, null);
        case 'en-GB':
            return React.createElement(FlagIconUnitedKingdom, null);
        case 'en-US':
            return React.createElement(FlagIconUnitedStates, null);
        case 'es':
            return React.createElement(FlagIconGlobe, null);
        default:
            return React.createElement(FlagIconOther, null);
    }
};
const Title = styled.span({
    marginLeft: medium,
    fontWeight: FontWeight.SemiBold,
});
const Body = styled(FlyoutBody, {
    flex: {
        grow: 1,
        shrink: 1,
        basis: 'auto',
    },
    overflow: 'auto',
});
const FullButton = styled(A, resetLinkDecorator, createGeneralDecorator(General.Default), {
    justifyContent: 'flex-start',
    width: '100%',
    display: 'block',
    marginBottom: average,
    border: {
        bottom: {
            style: 'none',
        },
    },
    backgroundColor: black05,
    ':hover': {
        textDecoration: 'none',
        backgroundColor: primary,
    },
});
const CultureName = styled.span({
    marginLeft: average,
});
const ModalContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});
const StyledButton = styled(DefaultButton, {
    marginBottom: medium,
});
const StyledHeading = styled(H4, {
    marginBottom: -small,
});
const __$hoisted_o0 = { textDecoration: 'none', color: primary };
