import React, { createContext, useEffect, useRef, useCallback, useMemo } from 'react';
import { TAB_KEY } from '@avensia/nitro5-scope';
import { getFocusableElements } from './focusable-elements';
export const TabTrappingContext = createContext({ enabled: false, resetTabFocus: () => { } });
export function TabTrap({ children, enabled }) {
    const elementRef = useRef();
    const focusableElements = useRef([]);
    useEffect(() => {
        var _a;
        let triggeredElement;
        function containTabbingToElements(event, elements) {
            const firstNode = elements[0];
            const lastNode = elements[elements.length - 1];
            const { activeElement } = document;
            if (!elements.includes(activeElement)) {
                event.preventDefault();
                event.shiftKey ? lastNode === null || lastNode === void 0 ? void 0 : lastNode.focus() : firstNode === null || firstNode === void 0 ? void 0 : firstNode.focus();
            }
            if (event.shiftKey && activeElement === firstNode) {
                event.preventDefault();
                lastNode === null || lastNode === void 0 ? void 0 : lastNode.focus();
            }
            if (!event.shiftKey && activeElement === lastNode) {
                event.preventDefault();
                firstNode === null || firstNode === void 0 ? void 0 : firstNode.focus();
            }
        }
        function onKeyDown(event) {
            if (event.keyCode === TAB_KEY || event.key === 'Tab') {
                containTabbingToElements(event, focusableElements.current);
            }
        }
        let observer = null;
        function updateFocusableElementsOnMutations() {
            if (elementRef.current) {
                observer = new MutationObserver(() => {
                    focusableElements.current = getFocusableElements(elementRef.current);
                });
                observer.observe(elementRef.current, { characterData: false, childList: true, subtree: true });
            }
        }
        function cleanup() {
            var _a, _b;
            document.removeEventListener('keydown', onKeyDown);
            (_b = (_a = triggeredElement) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
            observer === null || observer === void 0 ? void 0 : observer.disconnect();
        }
        if (enabled) {
            triggeredElement = document.activeElement;
            focusableElements.current = getFocusableElements(elementRef.current);
            (_a = focusableElements.current[0]) === null || _a === void 0 ? void 0 : _a.focus();
            document.addEventListener('keydown', onKeyDown);
            updateFocusableElementsOnMutations();
        }
        else {
            cleanup();
        }
        return cleanup;
    }, [enabled]);
    const setElementRef = useCallback((element) => {
        elementRef.current = element;
    }, []);
    const resetTabFocus = useCallback(() => {
        var _a, _b, _c;
        (_c = (_b = (_a = focusableElements.current) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.focus) === null || _c === void 0 ? void 0 : _c.call(_b);
    }, []);
    const value = useMemo(() => ({
        enabled,
        resetTabFocus,
    }), [enabled, resetTabFocus]);
    return (React.createElement(TabTrappingContext.Provider, { value: value },
        React.createElement("div", { ref: setElementRef }, children)));
}
