import currency from 'currency.js';
const defaultFormatter = (price, languageFormat) => currency(price, languageFormat).format();
const formatters = {
    eur: (price, languageFormat) => currency(price, {
        separator: languageFormat ? languageFormat.separator : ' ',
        decimal: languageFormat ? languageFormat.decimal : '.',
        symbol: '€',
        pattern: languageFormat ? languageFormat.pattern : '!#',
        negativePattern: languageFormat ? languageFormat.negativePattern : '-!#',
        precision: 2,
    }).format(),
    sek: (price, languageFormat) => currency(price, {
        separator: languageFormat ? languageFormat.separator : ' ',
        decimal: languageFormat ? languageFormat.decimal : ',',
        symbol: 'kr',
        pattern: languageFormat ? languageFormat.pattern : '# !',
        negativePattern: languageFormat ? languageFormat.negativePattern : '-# !',
        precision: Math.round(price) === price ? 0 : 2,
    }).format(),
    nok: (price, languageFormat) => currency(price, {
        separator: languageFormat ? languageFormat.separator : ' ',
        decimal: languageFormat ? languageFormat.decimal : ',',
        symbol: 'Kr',
        pattern: languageFormat ? languageFormat.pattern : '# !',
        negativePattern: languageFormat ? languageFormat.negativePattern : '-# !',
        precision: Math.round(price) === price ? 0 : 2,
    }).format(),
    usd: (price, languageFormat) => currency(price, {
        separator: languageFormat ? languageFormat.separator : ',',
        decimal: languageFormat ? languageFormat.decimal : '.',
        symbol: '$',
        pattern: languageFormat ? languageFormat.pattern : '!#',
        negativePattern: languageFormat ? languageFormat.negativePattern : '-!#',
        precision: Math.round(price) === price ? 0 : 2,
    }).format(),
    chf: (price, languageFormat) => currency(price, {
        separator: languageFormat ? languageFormat.separator : ' ',
        decimal: languageFormat ? languageFormat.decimal : '.',
        symbol: 'CHF',
        pattern: languageFormat ? languageFormat.pattern : '! #',
        negativePattern: languageFormat ? languageFormat.negativePattern : '-! #',
        precision: 2,
    }).format(),
    gbp: (price, languageFormat) => currency(price, {
        separator: languageFormat ? languageFormat.separator : ',',
        decimal: languageFormat ? languageFormat.decimal : '.',
        symbol: '£',
        pattern: languageFormat ? languageFormat.pattern : '!#',
        negativePattern: languageFormat ? languageFormat.negativePattern : '-!#',
        precision: Math.round(price) === price ? 0 : 2,
    }).format(),
    mxn: (price, languageFormat) => currency(price, {
        separator: languageFormat ? languageFormat.separator : ',',
        decimal: languageFormat ? languageFormat.decimal : '.',
        symbol: '$',
        pattern: languageFormat ? languageFormat.pattern : '!#',
        negativePattern: languageFormat ? languageFormat.negativePattern : '-!#',
        precision: Math.round(price) === price ? 0 : 2,
    }).format(),
    cad: (price, languageFormat) => currency(price, {
        separator: languageFormat ? languageFormat.separator : ',',
        decimal: languageFormat ? languageFormat.decimal : '.',
        symbol: '$',
        pattern: languageFormat ? languageFormat.pattern : '!#',
        negativePattern: languageFormat ? languageFormat.negativePattern : '-!#',
        precision: Math.round(price) === price ? 0 : 2,
    }).format(),
    aud: (price, languageFormat) => currency(price, {
        separator: languageFormat ? languageFormat.separator : ',',
        decimal: languageFormat ? languageFormat.decimal : '.',
        symbol: '$',
        pattern: languageFormat ? languageFormat.pattern : '!#',
        negativePattern: languageFormat ? languageFormat.negativePattern : '-!#',
        precision: Math.round(price) === price ? 0 : 2,
    }).format(),
    brl: (price, languageFormat) => currency(price, {
        separator: languageFormat ? languageFormat.separator : ',',
        decimal: languageFormat ? languageFormat.decimal : '.',
        symbol: 'R$',
        pattern: languageFormat ? languageFormat.pattern : '!#',
        negativePattern: languageFormat ? languageFormat.negativePattern : '-!#',
        precision: Math.round(price) === price ? 0 : 2,
    }).format(),
};
const languageFormatters = {
    fr: {
        separator: ' ',
        decimal: ',',
        pattern: '# !',
        negativePattern: '-# !',
    },
};
export function formatPrice(price, currency, language) {
    var _a;
    currency = currency.toLowerCase();
    const formatter = (_a = formatters[currency.toLowerCase()]) !== null && _a !== void 0 ? _a : defaultFormatter;
    const languageFormat = languageFormatters[language === null || language === void 0 ? void 0 : language.toLowerCase()];
    return formatter(price, languageFormat);
}
