import React from 'react';
import { styled } from '@glitz/react';
import { media, selector } from '@glitz/core';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
import { useSelector } from 'Shared/State';
import Link from 'Shared/Link';
import * as style from 'Shared/Style';
import { resetLinkDecorator } from 'Shared/Generic/A';
import { average, epsilon, General, gigantic, huge, humongous, mediaMinMedium, medium, tiny } from 'Shared/Style';
import { Hr } from 'Shared/Generic';
import { createSiteFooterBanner } from 'Shared/SiteBanner';
import { MidThemeBlock } from 'Shared/Theme';
import { h5Decorator } from 'Shared/Generic/H5';
import Social from './Social';
import { h6Decorator } from 'Shared/Generic/H6';
import { translate } from '@avensia/nitro5-scope';
import { format } from 'Shared/Translate';
import Image from 'Shared/Image/Ratio';
import { formatIcon, requireIfExists } from 'Shared/Icon/fontawesomefactory';
const FooterFull = () => {
    var _a, _b;
    const footer = useSelector(state => state.footer);
    const footerLinks = (_a = footer.lowerLinks) === null || _a === void 0 ? void 0 : _a.links;
    return (React.createElement(Footer, { appearance: [PageAppearance.Wide] },
        React.createElement(Groups, null, (_b = footer.linkGroups) === null || _b === void 0 ? void 0 :
            _b.map((item, i) => {
                var _a;
                return (React.createElement(Group, { key: i },
                    React.createElement(GroupHeader, null, item.header), (_a = item.links) === null || _a === void 0 ? void 0 :
                    _a.map((linkItem, index) => {
                        const filePath = requireIfExists(formatIcon(linkItem.fontAwesomeIcon));
                        return (React.createElement(FlexWrapper, { key: index },
                            !!filePath && React.createElement(StyledIcon, { src: { url: filePath }, lazy: true }),
                            React.createElement(GroupItem, { to: linkItem.url },
                                React.createElement("span", null, linkItem.title))));
                    })));
            }),
            React.createElement(Group, null),
            React.createElement(Group, { css: __$hoisted_o0 },
                React.createElement(GroupHeader, null, translate('/Footer/FollowUs')),
                React.createElement(SocialWrapper, null,
                    React.createElement(Social, { footer: footer })))),
        React.createElement(Hr, null),
        React.createElement(StyledCopyright, null,
            React.createElement(Flex, null,
                React.createElement(CopyrightText, null, format(translate('/Footer/CopyrightText'), new Date().getFullYear())), footerLinks === null || footerLinks === void 0 ? void 0 :
                footerLinks.map((item, i) => (React.createElement(CopyrightSubText, { key: i },
                    React.createElement(FooterLink, { to: item.href, target: item.target }, item.title))))))));
};
export default FooterFull;
const Footer = styled(Basic, {
    padding: {
        x: gigantic * 2,
        top: huge * 2,
        bottom: gigantic,
    },
    color: ({ siteTheme }) => siteTheme.footer.textColor,
});
const Groups = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: humongous * 2,
});
const Group = styled.div(Object.assign(Object.assign({ flexShrink: 1 }, selector(':not(:last-child)', {
    flex: {
        grow: 1,
        shrink: 1,
        basis: 0,
    },
})), selector(':last-child', {
    flex: {
        grow: 1,
        shrink: 1,
        basis: 0,
    },
})));
const GroupItem = styled(Link, resetLinkDecorator, Object.assign(Object.assign({ display: 'table', whiteSpace: 'normal', margin: { y: style.small }, padding: { x: '0.5rem' } }, selector(':hover', {
    backgroundColor: ({ siteTheme }) => siteTheme.footer.hoverBackgroundColor,
    borderRadius: tiny,
})), { font: {
        size: style.epsilon + 2,
        weight: 400,
    } }));
const GroupHeader = styled(styled.H2, h6Decorator, {
    padding: { x: '0.5rem' },
    margin: {
        top: 0,
        bottom: style.small,
    },
    font: {
        size: medium + 2,
        weight: 600,
    },
});
const CopyrightText = styled(styled.P, h5Decorator, Object.assign(Object.assign({}, media(mediaMinMedium, { fontSize: epsilon })), { marginRight: humongous + average }));
const CopyrightSubText = styled(CopyrightText, {
    margin: { right: huge },
});
const SocialWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
});
const Copyright = createSiteFooterBanner(MidThemeBlock, General.Default);
const StyledCopyright = styled(Copyright, { opacity: 0.85, textAlign: 'left', marginTop: -40 });
const Flex = styled.div({
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
});
const FooterLink = styled(Link, resetLinkDecorator, {
    font: {
        weight: 'normal',
        size: style.epsilon,
    },
    ':hover': {
        textDecoration: 'underline',
    },
});
const FlexWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
});
const StyledIcon = styled(Image, {
    width: medium,
    position: 'absolute',
    left: -medium,
});
const __$hoisted_o0 = { textAlign: 'end' };
