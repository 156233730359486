import { __rest } from "tslib";
import React, { useCallback } from 'react';
import { translate, DynamicData } from '@avensia/nitro5-scope';
import { getCart } from 'Shared/dynamic-data';
import { LinkStyledButton } from 'Shared/Button';
import { testIdProps } from '../../test-automation';
import { useSelector } from 'Shared/State';
import miniCartIsDisabled from 'SiteLayout/MiniCart/is-disabled-selector';
import { useDispatch } from 'react-redux';
import { toggleMiniCart } from 'SiteLayout/MiniCart/action-creators';
import { Cart } from 'Shared/Icon';
import RoundIndicator from 'Shared/RoundIndicator';
import { format } from 'Shared/Translate';
export default function CartButton(_a) {
    var restProps = __rest(_a, []);
    const isDisabled = useSelector(state => miniCartIsDisabled(state));
    const isOpen = useSelector(state => state.miniCart.isOpen);
    const dispatch = useDispatch();
    const toggle = useCallback(() => {
        dispatch(toggleMiniCart());
    }, [dispatch]);
    return (React.createElement(DynamicData, { data: getCart(), fallbackData: { items: [], additional: undefined, viewData: undefined }, shouldResolve: !isDisabled }, cart => {
        const cartCount = cart.items.reduce((a, i) => a + i.quantity, 0);
        const multipleCart = format(translate('/MainMenu/Cart/Multiple'), cartCount);
        // These has to be 3 separate calls to translate as the
        // translation-loader won't pick them up if you use a dynamic string.
        const title = cartCount === 0
            ? translate('/MainMenu/Cart/Empty')
            : cartCount === 1
                ? translate('/MainMenu/Cart/Single')
                : multipleCart;
        return (React.createElement(LinkStyledButton, Object.assign({ title: title, onClick: toggle, "aria-expanded": isOpen, "aria-label": translate('/Shared/ShoppingCart') }, testIdProps.MiniCartButton, restProps),
            React.createElement(Cart, null),
            !isDisabled && React.createElement(RoundIndicator, { cartCount: cartCount })));
    }));
}
