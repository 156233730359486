import { __awaiter, __rest } from "tslib";
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { styled } from '@glitz/react';
import { useLoadResource } from './utils';
import { ImageBase, Placeholder } from './components';
import noImage from './noimage';
import useSubscribeToBrowserCache from './use-subscribe-to-browser-cache';
import { loadIntersectionObserverPolyfillIfNeeded } from 'Shared/load-intersection-observer-polyfill-if-needed';
import { useLayoutEffect } from 'Shared/use-layout-effect';
const loadObserverPromise = loadIntersectionObserverPolyfillIfNeeded();
const ROOT_MARGIN = 400;
var Status;
(function (Status) {
    Status[Status["Pending"] = 0] = "Pending";
    Status[Status["Rejected"] = 1] = "Rejected";
    Status[Status["Fulfilled"] = 2] = "Fulfilled";
})(Status || (Status = {}));
export default styled(function LazyImage(_a) {
    var { originalSrc, subscribeToCache } = _a, restProps = __rest(_a, ["originalSrc", "subscribeToCache"]);
    const currentSrc = restProps.src;
    const previousSrcRef = useRef();
    const currentSrcSet = restProps.srcSet;
    const previousSrcSetRef = useRef();
    const [status, setStatus] = useState(currentSrc ? Status.Pending : Status.Rejected);
    const cachedResource = useSubscribeToBrowserCache(originalSrc, subscribeToCache);
    const loadResource = useLoadResource();
    const elementRef = useRef(null);
    const elementRefCallback = useCallback((el) => (elementRef.current = el), []);
    const unsubscribeObserverRef = useRef(null);
    const mountedRef = useRef(true);
    useLayoutEffect(() => {
        if (previousSrcRef.current !== currentSrc || previousSrcSetRef.current !== currentSrcSet) {
            if (currentSrc) {
                if (status !== Status.Pending) {
                    setStatus(Status.Pending);
                }
                if (unsubscribeObserverRef.current) {
                    unsubscribeObserverRef.current();
                }
                const element = elementRef.current;
                const initObserver = () => __awaiter(this, void 0, void 0, function* () {
                    yield loadObserverPromise;
                    const observer = new IntersectionObserver(intersections => {
                        if (!intersections.every(intersection => !intersection.isIntersecting)) {
                            loadResource(originalSrc, currentSrc, currentSrcSet).then(({ isFulfilled, isCurrentLoad }) => {
                                if (isCurrentLoad && mountedRef.current) {
                                    setStatus(isFulfilled ? Status.Fulfilled : Status.Rejected);
                                }
                            });
                            if (unsubscribeObserverRef.current) {
                                unsubscribeObserverRef.current();
                            }
                        }
                    }, { rootMargin: `${ROOT_MARGIN}px 50% ${ROOT_MARGIN}px 50%` });
                    observer.observe(element);
                    unsubscribeObserverRef.current = () => {
                        observer.unobserve(element);
                        unsubscribeObserverRef.current = null;
                    };
                });
                initObserver();
            }
            else {
                setStatus(Status.Rejected);
            }
            previousSrcRef.current = currentSrc;
            previousSrcSetRef.current = currentSrcSet;
        }
    }, [currentSrc, currentSrcSet, loadResource, originalSrc, status]);
    useEffect(() => () => {
        mountedRef.current = false;
        if (unsubscribeObserverRef.current) {
            unsubscribeObserverRef.current();
        }
    }, []);
    if (currentSrc) {
        if (status === Status.Fulfilled) {
            return React.createElement(ImageBase, Object.assign({}, restProps, { src: currentSrc, ref: elementRefCallback }));
        }
        // Prevent `srcset` to interfere cached-, placeholder- and no-image image
        delete restProps.srcSet;
        delete restProps.src;
        if (cachedResource) {
            return React.createElement(ImageBase, Object.assign({}, restProps, { src: cachedResource.src, ref: elementRefCallback }));
        }
        if (status === Status.Pending) {
            return React.createElement(ImageBase, Object.assign({}, restProps, { css: __$hoisted_o0, ref: elementRefCallback }));
        }
    }
    // Prevent `srcset` to interfere with no image image
    delete restProps.src;
    delete restProps.srcSet;
    return React.createElement(Placeholder, Object.assign({}, restProps, { src: noImage, ref: elementRefCallback }));
});
const __$hoisted_o0 = { opacity: 0, transition: { duration: 0 } };
