import { formatObject } from '@avensia/nitro5-scope';
export function format(phrase) {
    if (arguments.length === 1) {
        return phrase;
    }
    else if (typeof arguments[1] === 'object' && !!arguments[1]) {
        return formatObject(phrase, arguments[1]);
    }
    else {
        return formatArray(phrase, Array.prototype.slice.call(arguments, 1));
    }
}
function formatArray(phrase, values) {
    if (!phrase) {
        return phrase;
    }
    const regex = /{(\d+)}/g;
    if (process.env.NODE_ENV !== 'production') {
        const placeholders = phrase.match(regex) || [];
        if (placeholders.length !== values.length) {
            return phrase;
        }
    }
    return values.length === 0
        ? phrase
        : phrase.replace(regex, (match, index) => typeof values[index] !== 'undefined' ? values[index] : match);
}
