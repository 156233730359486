import React from 'react';
import { styled } from '@glitz/react';
import { isIE } from '@avensia/nitro5-scope';
import factory from './factory';
import * as style from '../Style';
const Spinner = factory((props) => {
    return (React.createElement(styled.Svg, Object.assign({ viewBox: "25 25 50 50" }, props, { css: Object.assign({ verticalAlign: 'initial' }, style.createAnimationDecorator({
            name: {
                '100%': {
                    transform: 'rotate(360deg)',
                },
            },
            duration: isIE() ? 2000 : 2000,
            timingFunction: 'linear',
            iterationCount: 'infinite',
        })) }),
        React.createElement(styled.Circle, { cx: "50", cy: "50", r: "20", fill: "none", stroke: "currentColor", strokeWidth: "5", strokeMiterlimit: "10", strokeDasharray: isIE() ? '1, 200' : '30, 200', strokeDashoffset: "0", strokeLinecap: "round", css: !isIE() &&
                style.createAnimationDecorator({
                    name: {
                        '0%': {
                            strokeDasharray: '1px, 200px',
                            strokeDashoffset: '0px',
                        },
                        '50%': {
                            strokeDasharray: '89px, 200px',
                            strokeDashoffset: '-35px',
                        },
                        '100%': {
                            strokeDasharray: '89px, 200px',
                            strokeDashoffset: '-124px',
                        },
                    },
                    duration: 1500,
                    timingFunction: 'ease-in-out',
                    iterationCount: 'infinite',
                }) })));
});
export default Spinner;
